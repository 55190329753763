import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Breadcrumb.scss";

const Breadcrumb = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter((path) => path !== "");

  if (paths.length === 0 || paths[0] === "dashboard") {
    return null;
  }

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const breadcrumbNames = {
    "": "Dashboard",
    movies: "Movies",
    location: "Locations",
    updatemovie: "Edit",
    Edit: "Edit",
  };

  const pathToDisplay =
    paths.includes("updatemovie") ||
    paths.includes("Edit") ||
    paths.includes("MovieLanguage")
      ? ["movies", "updatemovie"]
      : paths;

  return (
    <div id="bread">
      <nav aria-label="breadcrumb" className="breadCrumb2">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Dashboard</Link>
          </li>
          {pathToDisplay.map((path, index) => {
            const url = `/${pathToDisplay.slice(0, index + 1).join("/")}`;
            const displayName =
              breadcrumbNames[path] || capitalizeFirstLetter(path);
            return (
              <li className="breadcrumb-item" key={index}>
                {index === pathToDisplay.length - 1 ? (
                  <span>{displayName}</span>
                ) : (
                  <Link to={url}>{displayName}</Link>
                )}
                {index !== pathToDisplay.length - 1 && (
                  <span className="breadcrumb-separator">{""}</span>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
