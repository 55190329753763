import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './GlobalSearch.scss'; 

const GlobalSearch = () => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = async () => {
    try {
      const response = await fetch(`https://wherewasitshot.com/api/meta/search?query=${query}`);
      const data = await response.json();

      // console.log('API response:', data);

      // Assuming 'movieId' is part of the response data
      const movieId = data[0]?.movieId;

      // Use navigate to trigger navigation with search input as a URL parameter
      navigate(`/search-result/${query}`, { state: { movieId } });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="global-search-container">
      <div>
        <input
          type="text"
          value={query}
          placeholder="Search....."
          onChange={(e) => setQuery(e.target.value)}
          className='global-search-field'
        />
        <button onClick={handleSearch}>Search</button>
      </div>
    </div>
  );
};

export default GlobalSearch;
