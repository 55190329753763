import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./LocationDetail.scss";

const MovieShotedHereButton = ({ id }) => {
  const [movieImages, setMovieImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch movie images when the component mounts
    fetch(`https://wherewasitshot.com/api/location/getMoviesByLocation/${id}`)
      .then((response) => response.json())
      .then((data) => {
        // Filter out duplicate images based on movieId
        const uniqueImages = data.reduce((unique, movie) => {
          if (!unique.some((item) => item.movieId === movie.movieId)) {
            unique.push(movie);
          }
          return unique;
        }, []);

        setMovieImages(uniqueImages);
        setLoading(false); // Mark loading as complete
      })
      .catch((error) => {
        console.error("Error fetching movie images:", error);
        setLoading(false); // Mark loading as complete, even in case of an error
      });
  }, [id]);

  return (
    <div className="container movieshothere">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="row mb-4">
          {Array.isArray(movieImages) && movieImages.length > 0 ? (
            movieImages.map((movie, index) => (
              <div key={index} className="col-md-4 imagecon">
                <div className="cardr">
                  <Link to={`/movie/${movie.movieId}`}>
                    <img
                      src={movie.image}
                      alt={movie.movieName}
                      className="movieShotimages"
                    />
                  </Link>
                  <div className="card-body">
                    <h5 className="MovieshotlocName text-center " >{movie.movieName.toUpperCase()}</h5>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-danger text-center">No matched data on the list </p>
          )}
        </div>
      )}
    </div>
  );
};

export default MovieShotedHereButton;