import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./LocationDetail.scss";

const SongsAndScenesButton = ({}) => {
  const [songAndSceneData, setSongAndSceneData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    // Fetch songs and scenes data when the component mounts
    fetch(`https://wherewasitshot.com/api/songsandscene/bylocation/${id}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Fetched songs and scenes data:", data);
        setSongAndSceneData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching songs and scenes data:", error);
        setLoading(false);
      });
  }, [id]);

  // Filter out items with type "scene"
  const filteredSongAndSceneData = songAndSceneData.filter(
    (item) => item.type !== "scene"
  );

  return (
    <div className="container songsandscene">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="row mb-4">
          {filteredSongAndSceneData.length === 0 ? (
            <p className="text-danger text-center">No matched data available</p>
          ) : (
            filteredSongAndSceneData.map((item, index) => (
              <div key={index} className="col-md-4">
                <div className="cardar youtubeImage " 
                style={{ justifyContent:'center' , textAlign:'center'}}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={getYouTubeThumbnail(item.url)}
                      alt={item.description}
                      className="card-img-top SongsImage"
                      // style={{ width: '80%', height: '150px' }}
                    />
                  </a>
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ fontSize: "14px" ,   color: 'rgb(0, 128, 255)' , fontWeight:'400'}}>
                      {item.description.toUpperCase()}
                    </h5>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

function getYouTubeThumbnail(url) {
  const videoId = getYouTubeVideoId(url);

  if (videoId) {
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  }

  return null;
}

function getYouTubeVideoId(url) {
  const videoIdMatches = url.match(
    /([?&]v=|\/embed\/|\/[0-9A-Za-z_-]{11}\/|\/[0-9A-Za-z_-]{11}\?|youtu\.be\/)([0-9A-Za-z_-]{11})/
  );
  return (videoIdMatches && videoIdMatches[2]) || null;
}

export default SongsAndScenesButton;
