import React from "react";
import Header1 from "../../Image/header1.jpg";
import Header2 from "../../Image/header05.jpg";
import Header3 from "../../Image/header02.jpg";
import Navbar from "../Home/Navbar/Navbar";
import "./Carousel.scss";
import MovieCarousel from "../Home/MovieCarousel";
import LocationCarousel from "./LocationCarousel";
import Footer from "./Footer";
import ExploreLocation from "./ExploreLocation";
import GlobalSearch from "../Movies/GlobalSearch";


const Carousel = () => {
  return (
    <>
      <Navbar />
      <div className="carousel">
        <div className="explore-text">
          <p className="explorepp">Explore your favorite movies / locations</p>
          <div className="subtext">
          </div>
          <GlobalSearch />
        </div>

        <div
          id="carouselExampleInterval"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
              <img src={Header1} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src={Header3} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src={Header2} class="d-block w-100" alt="..." />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
 
      <div className="listmovie">
        <MovieCarousel />

        <LocationCarousel />
      </div>

      <div>
        <ExploreLocation />
      </div>
      <div className="home_footer">

        <Footer />
      </div>
    </>
  );
};

export default Carousel;
