import React, { useState, useEffect } from "react";
import axios from "axios";
import "./MovieCarousel.scss";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

function MovieCarousel() {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    // Fetch data from your API using Axios
    axios
      .get("https://wherewasitshot.com/api/movie/top10Movie") // Replace with your API endpoint for movie images
      .then((response) => {
        const apiImages = response.data;
        setImages(apiImages);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!transitioning) {
        nextSlide(); // Auto-advance to the next slide if not transitioning
      }
    }, 3000); // Change the time interval as needed (in milliseconds)

    return () => clearInterval(interval);
  }, [images, transitioning]);

  // const prevSlide = () => {
  //   if (!transitioning) {
  //     setTransitioning(true);
  //     setCurrentIndex((prevIndex) => {
  //       const newIndex = (prevIndex - 1 + images.length) % images.length;
  //       setTimeout(() => setTransitioning(false), 500); // Reset transitioning after the animation
  //       return newIndex;
  //     });
  //   }
  // };

  // const nextSlide = () => {
  //   if (!transitioning) {
  //     setTransitioning(true);
  //     setCurrentIndex((prevIndex) => {
  //       const newIndex = (prevIndex + 1) % images.length;
  //       setTimeout(() => setTransitioning(false), 500); // Reset transitioning after the animation
  //       return newIndex;
  //     });
  //   }
  // };

  function nextSlide() {
    if (currentIndex === images.length - 1) {
      // If at the end, loop back to the beginning
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }
  
  function prevSlide() {
    if (currentIndex === 0) {
      // If at the beginning, loop to the end
      setCurrentIndex(images.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  }

  return (
    <div className="movie-carousel">
      <h3 className="top10mov">
        {/* Latest Movies */}
        LATEST MOVIES
        </h3>
      <button onClick={prevSlide} className="prev-button1">
        {/* <NavigateBeforeIcon style={{ fontSize: 60 }} /> */}
      </button>
      <div className="slider">
        <div
          className="slider-inner"
          style={{
            width: `${images.length * 340}px`,
            transform: `translateX(-${currentIndex * 320}px)`,
            transition: transitioning ? "transform 0.5s ease" : "none", // Smooth transition only when transitioning
          }}
        >
          {images.map((imageData) => (
            <div className="card" key={imageData.movieId}>
              <Link to={`/movie/${imageData.movieId}`}>
                <div className="hhh">
                  <img
                    src={imageData.imageUrl}
                    alt={`Slide ${imageData.movieId}`}
                    lassName="cardar-img-top justify-content-center"
                  />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <button onClick={nextSlide} className="next-button1">
        {/* <NavigateNextIcon style={{ fontSize: 60 }} /> */}
      </button>
    </div>
  );
}

export default MovieCarousel;
