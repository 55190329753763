import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./Topbar.scss";
import { Link } from "react-router-dom";

const TagTopbar = ({ onSearchInputChange }) => {
  return (
    <div className="topp">
      <div className="main">
        <div className="topbar">
          <div id="toggle"></div>

          <div className="search">
            <label>
              <input
                type="text"
                placeholder="Search here"
                onChange={onSearchInputChange}
              />
              <SearchIcon id="search_icon" />
            </label>
          </div>

          {/* <div className="add-movie-button">
            <Link className="btn addmovie" to="/movies/add">
              Add Movie
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TagTopbar;
