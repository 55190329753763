import React, { useState, useEffect } from "react";
import "./MovieLangauge.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumb from "../../Breadcrumb";
import Navigation from "../../component/Navigation";
import { useNavigate } from "react-router-dom";


const MovieLanguage = () => {
  const [LanguageValues, setLanguageValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newLanguageName, setNewLanguageName] = useState("");
  const [editLanguageId, setEditLanguageId] = useState(null);
  const [showConfirmation, setshowConfirmation] = useState(null);
  const navigate = useNavigate();

  const fetchLanguageValues = async () => {
    setIsLoading(true);
    try {
      // Replace with your actual API endpoint to fetch language values
      const response = await fetch(
        "https://wherewasitshot.com/api/meta/movielanguage/get"
      );
      const data = await response.json();
      setLanguageValues(data);
    } catch (error) {
      console.error("Error fetching language values:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLanguageValues();
  }, []);

  const handleAddLanguageClick = () => {
    setShowAddForm(!showAddForm); // Toggle the form visibility
    setNewLanguageName("");
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const token = sessionStorage.getItem("token");
      // Replace with your actual API endpoint to add a new language
      const response = await fetch(
        "https://wherewasitshot.com/api/meta/movielanguage/add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: newLanguageName,
          }),
        }
      );

      if (response.ok) {
        // language added successfully, update the language values and hide the form
        fetchLanguageValues();
        setShowAddForm(false);
        setNewLanguageName("");
      }
      else if (response.status === 403) {
        alert("Token expired. Redirecting to /admin");
        navigate('/admin');
      }
      else {
        console.error("Error adding language:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding language:", error);
    }
  };

  const handleEditClick = (languageId) => {
    setEditLanguageId(languageId);
    // Find the language with the specified ID and populate the form fields
    const languageToEdit = LanguageValues.find((language) => language.id === languageId);
    if (languageToEdit) {
      setNewLanguageName(languageToEdit.name);

    }
  };

  const handleCancelEdit = () => {
    setEditLanguageId(null);
    setNewLanguageName("");

  };

  const handleUpdateLanguage = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `https://wherewasitshot.com/api/meta/movielanguage/edit/${editLanguageId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: newLanguageName,

          }),
        }
      );

      if (response.ok) {
        // Tag updated successfully, reset form fields and fetch updated data
        fetchLanguageValues();
        setEditLanguageId(null);
        setNewLanguageName("");
      } else if (response.status === 403) {
        alert("Token expired. Redirecting to /admin");
        navigate('/admin');
      } else {
        console.error("Error updating tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating tag:", error);

    }
  };


  const handleDeleteLanguage = (languageId) => {
    // Set the showConfirmation state to trigger the confirmation dialog
    setshowConfirmation(languageId);
  };
  const confirmDeleteLanguage = async () => {
    try {
      const token = sessionStorage.getItem("token");

      const response = await fetch(
        `https://wherewasitshot.com/api/meta/movielanguage/delete/${showConfirmation}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        // Tag deleted successfully, fetch updated data
        fetchLanguageValues();
        setshowConfirmation(null);
      } else if (response.status === 403) {
        alert("Token expired. Redirecting to /admin");
        navigate('/admin');
      } else {
        console.error("Error deleting tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting tag:", error);


    }
  };


  return (
    <div className="container-lang ">
      <Breadcrumb />
      <Navigation />
      <div className="row justify-content-center">
        <div className="col-md-6 mt-5">
          <div className="row col-12">
            <div className="add-language-button">
              {showAddForm ? (
                <button
                  className="btn addlanguagecancel btn-danger"
                  onClick={handleAddLanguageClick}
                >
                  CANCEL
                </button>
              ) : (
                <button
                  className="btn addlanguage btn-primary"
                  onClick={handleAddLanguageClick}
                >
                  ADD LANGUAGE
                </button>
              )}
            </div>
          </div>

          {showAddForm && (
            <div className="mt-5">
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <label htmlFor="newLanguageName">Langauge</label>
                  <input
                    type="text"
                    className="form-control"
                    id="newLanguageName"
                    value={newLanguageName}
                    onChange={(e) => setNewLanguageName(e.target.value)}
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary m-2 addLangbtn"
                >
                  ADD
                </button>
              </form>
            </div>
          )}

          <div className="mt-3">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <table className="table table-striped table-hover shadow table-responsive">
                <thead className="table">
                  <tr>
                    <th className="id">#</th>
                    <th className="catType">Langauge</th>
                    <th className="actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {LanguageValues.map((language, index) => (
                    <tr key={language.id}>
                      <td className="id">{index + 1}</td>
                      <td className="catType">
                        {editLanguageId === language.id ? (
                          <input
                            className="input"
                            type="text"
                            value={newLanguageName}
                            onChange={(e) => setNewLanguageName(e.target.value)}
                          />
                        ) : (
                          language.name
                        )}
                      </td>
                      <td>
                        {editLanguageId === language.id ? (
                          <>
                            <button
                              className="btn btn-outline-primary"
                              onClick={handleUpdateLanguage}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-outline-danger"
                              onClick={handleCancelEdit}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <div className="button1">
                            <button
                              className="button"
                              onClick={() => handleEditClick(language.id)}
                            >
                              <EditIcon className="EditIcon" />{" "}
                            </button>
                            <button
                              className="button"
                              onClick={() => handleDeleteLanguage(language.id)}
                            >
                              <DeleteIcon className="DeleteIcon" />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {showConfirmation && (
            <div className="confirmation-popup">
              <div className="confirmation-popup-content">
                <p>Are you sure you want to delete this Language?</p>

                <div className="confirmation-buttons">
                  <button
                    className="cancel-button"
                    onClick={() => setshowConfirmation(null)}
                  >
                    Cancel
                  </button>
                  <button className="confirm-button" onClick={confirmDeleteLanguage}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MovieLanguage;
