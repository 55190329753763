import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../Home/Navbar/Navbar";
import Paginations from "../../Paginations";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import Footer from "../Footer";

const API_BASE_URL = "https://wherewasitshot.com/api";

const ExploreLocationDetail = () => {
  const { location } = useParams();
  const [originalLocations, setOriginalLocations] = useState([]); // Store the original locations data
  const [locations, setLocations] = useState([]); // Store the filtered locations data
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [selectedFilter, setSelectedFilter] = useState("date-desc");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState("");
  const locationsPerPage = 12;
  const [availableCategories, setAvailableCategories] = useState([]);

  useEffect(() => {
    // Fetch location categories from your API endpoint
    fetch("https://wherewasitshot.com/api/meta/locationcategory/get")
      .then((response) => response.json())
      .then((data) => {
        // Extract the category names from the response data
        const categoryNames = data.map((category) => category.name);
        // Update the selectedCategory and availableCategories state
        setSelectedCategory("All"); // Set a default value
        setAvailableCategories(["All", ...categoryNames]);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch locations based on the selected location
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_URL}/location/retrievelocation/${location}`
        );
        const locationsData = await response.json();
        setOriginalLocations(locationsData);
        setLocations(locationsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    // Update page title with the selected location
    document.title = `Locations in ${location.charAt(0).toUpperCase() + location.slice(1)} - Where Was It Shot`;
  }, [location]);

  useEffect(() => {
    // Call filterLocationsWithSearchTerm whenever the searchTerm changes
    filterLocationsWithSearchTerm();
  }, [searchTerm, originalLocations]); // Include originalLocations as a dependency

  // Function to filter locations based on the search term
  const filterLocationsWithSearchTerm = () => {
    const filteredLocations = originalLocations.filter((location) =>
      location.locationName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setLocations(filteredLocations);
  };

  const handleSortChange = (value) => {
    setSelectedFilter(value);

    const sortedData = [...locations]; // Create a copy of the locations array to avoid modifying the original data

    if (value === "date-desc") {
      // Sort by the latest date in descending order
      sortedData.sort(
        (a, b) => new Date(b.lastUpdatedDate) - new Date(a.lastUpdatedDate)
      );
    } else {
      // For alphabetical sorting (A-Z and Z-A), use the localeCompare method
      sortedData.sort((a, b) => {
        const sortOrder = value === "title-asc" ? 1 : -1;
        return (
          a.locationName.localeCompare(b.locationName, undefined, {
            sensitivity: "base",
          }) * sortOrder
        );
      });
    }

    // Update the locations with the sorted data
    setLocations(sortedData);
  };

  const handleCategoryChange = async (value) => {
    setSelectedCategory(value);

    // Step 1: Fetch data based on the selected location (continent, country, etc.)
    const locationResponse = await fetch(
      `${API_BASE_URL}/location/retrievelocation/${location}`
    );
    const locationData = await locationResponse.json();

    if (locationData.length === 0) {
      setLocations([]);
      setMessage("No location found.");
      return;
    }

    // Step 2: Filter data based on the selected category
    if (value === "All") {
      // If "All" is selected, show all locations from the location-based data
      setLocations(locationData);
    } else {
      // Filter data from the location-based data based on the selected category
      const filteredData = locationData.filter(
        (location) => location.type === value
      );

      if (filteredData.length === 0) {
        setLocations([]);
        setMessage("No location matched.");
      } else {
        setLocations(filteredData);
      }
    }
  };

  // Calculate the indexes for the locations to display on the current page
  const indexOfLastLocation = currentPage * locationsPerPage;
  const indexOfFirstLocation = indexOfLastLocation - locationsPerPage;
  const currentLocations = locations.slice(
    indexOfFirstLocation,
    indexOfLastLocation
  );

  // Function to change the current page
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate an array of page numbers for pagination
  const pageNumbers = Array.from(
    { length: Math.ceil(locations.length / locationsPerPage) },
    (_, i) => i + 1
  );

  return (
    <div className="locationList">
      <Navbar />
      <p
        className="text-center browseLocation"
        style={{ textTransform: "uppercase" }}
      >
        LOCATIONS IN {location.charAt(0).toUpperCase() + location.slice(1)}
      </p>
      <br />
      <div className="container">
        <div className="filterlocation">
          <div className="search-container col-md-5">
            <input
              type="text"
              placeholder="Search locations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SearchIcon className="searchicon" />
          </div>

          {/* Category Dropdown */}
          <div className="categoryFilter col-md-2 ms-2">
            <div className="categoryFilter-container ">
              <label htmlFor="categoryFilter"></label> {/* Add label */}
              <select
                id="categoryFilter"
                name="categoryFilter"
                value={selectedCategory}
                onChange={(e) => handleCategoryChange(e.target.value)}
              >
                <option value="All">Category: All</option>{" "}
                {/* Placeholder option */}
                {availableCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="filter col-md-2">
            <div className="filter-containerr sortby">
              <label htmlFor="filter"></label>
              <select
                id="filter"
                name="filter"
                value={selectedFilter}
                onChange={(e) => handleSortChange(e.target.value)}
              >
                <option value="date-desc">Sort: Latest</option>
                <option value="title-asc">Title (A-Z)</option>
                <option value="title-desc">Title (Z-A)</option>
              </select>
            </div>
          </div>
        </div>

        <div className="container">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 listingLocation">
              {locations.length === 0 ? (
                // Display a message when no matching locations are found
                <p>{message}</p>
              ) : (
                currentLocations.map((location) => (
                  <div
                    key={location.locationName}
                    className="col mb-4 locations"
                  >
                    <div className="cardar">
                      <Link
                        to={`/location/${location.id}`}
                        className="cardar-link"
                      >
                        <img
                          src={location.image}
                          alt={location.locationName}
                          className="cardar-img-top"
                        />
                        <div className="cardar-body">
                          <h5
                            className="cardar-title"
                            style={{
                              fontSize: "13px",
                              color: "rgb(0, 128, 255)",
                              fontWeight: "400",
                            }}
                          >
                            {location.locationName.toUpperCase()}
                          </h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
        {/* Pagination */}
        <div className="row">
          <div className="col text-center">
            <Paginations
              currentPage={currentPage}
              totalPages={Math.ceil(locations.length / locationsPerPage)}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ExploreLocationDetail;
