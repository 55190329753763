import React, { useEffect } from "react";
import "./Navbar.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import TheatersIcon from "@mui/icons-material/Theaters";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import Logo from "../../../Image/wwis.png";

const Navbar = () => {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = getPageTitle(location.pathname);
    document.title = pageTitle;
  }, [location]);

  const getPageTitle = (path) => {
    switch (path) {
      case "/":
        return "Where Was It Shot - Online Catalog of Indian Movies Locations";
      case "/moviesList":
        return "Browse Movies - Where Was It Shot";
      case "/locationsList":
        return "Browse Locations - Where Was It Shot";
      case "/contact":
        return "Contact - Where Was It Shot";
      default:
        return "Where Was It Shot";
    }
  };

  return (
    <div className="navbbar fixed-top">
      <nav className="navbar navbar-expand-lg bg-white fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand me-auto" to="/">
            <img
              src={Logo}
              alt="Where was it shot"
              className="logo_icon"
              width="180"
              height="50"
              style={{ paddingLeft: "18px" }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  exact
                  to="/"
                >
                  Home <HomeIcon className="navIcon" style={{ fontSize: "20px" }} />
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/moviesList"
                >
                  Movies <TheatersIcon className="navIcon" style={{ fontSize: "18px" }}/>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/locationsList"
                >
                  Locations <LocationOnIcon className="navIcon" style={{ fontSize: "20px" }}/>
                </NavLink>
              </li>

              <li className="nav-item contact">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/contact"
                >
                  Contact <ContactMailIcon className="navIcon" style={{ fontSize: "19px" }}/>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
