import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigation.scss";
import TagIcon from "@mui/icons-material/Tag";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CameraEnhanceOutlinedIcon from "@mui/icons-material/CameraEnhanceOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Image from ".././../Image/logo_wwis2.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Navigation = () => {
  const location = useLocation();
  const [isMetaOpen, setIsMetaOpen] = useState(false);
  const navigate = useNavigate();
  const [, setLogout] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [isNavigationBarVisible, setIsNavigationBarVisible] = useState(
    window.innerWidth >= 768
  );

  const CustomLink = ({ to, children }) => (
    <Link to={to} className="custom-link">
      {children}
    </Link>
  );

  const handleMetaToggle = () => {
    setIsMetaOpen((prevState) => !prevState);
  };

  const handleToggleNavigationBar = () => {
    setIsNavigationBarVisible((prevState) => !prevState);
  };

  const handleOtherNavClick = () => {
    setIsMetaOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("auth")) navigate("/");
  }, []);

  const logoutHandler = (e) => {
    setShowConfirmation(true);
  };

  

  const confirmLogout = () => {
    localStorage.removeItem("auth");
    navigate("/admin"); 
    setLogout(true);
    setShowConfirmation(false);
  };
  

  const cancelLogout = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsNavigationBarVisible(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="navi">
      {window.innerWidth < 768 && (
        <button
          className="toggle-navigation-button"
          onClick={handleToggleNavigationBar}
        >
          {isNavigationBarVisible ? (
            <FontAwesomeIcon icon={faTimes} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faBars} size="2x" />
          )}
        </button>
      )}

      {isNavigationBarVisible && (
        <div className="navigation">
          <ul>
            <li className="dash">
              <Link to="/" className="custom-link">
                <span className="icon">
                  <img
                    src={Image}
                    alt="Where was it shot"
                    className="image-icon"
                    // width="260"
                    // height="65"
                    width="220"
                    height="40"
                    style={{ paddingLeft: "8px" }}
                  />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to="/dashboard"
                className={`nav-link ${
                  location.pathname === "/dashboard" ? "active" : ""
                }`}
                onClick={handleOtherNavClick}
              >
                <span className="icon">
                  <DashboardIcon className="ion-icon" />
                </span>
                <span className="title">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/movies"
                className={`nav-link ${
                  location.pathname === "/movies" ? "active" : ""
                }`}
                onClick={handleOtherNavClick}
              >
                <span className="icon">
                  <CameraEnhanceOutlinedIcon className="ion-icon" />
                </span>
                <span className="title">Movies</span>
              </Link>
            </li>
            <li>
              <Link
                to="/location"
                className={`nav-link ${
                  location.pathname === "/location" ? "active" : ""
                }`}
                onClick={handleOtherNavClick}
              >
                <span className="icon">
                  <LocationOnOutlinedIcon className="ion-icon" />
                </span>
                <span className="title">Locations</span>
              </Link>
            </li>
            <li className={`menu-item ${isMetaOpen ? "active" : ""}`}>
              <a href="#" onClick={handleMetaToggle} className="nav-link">
                <span className="icon">
                  <TagIcon className="ion-icon" />
                </span>
                <span className="title">Meta</span>
                <span
                  className={`dropdown-icon ${isMetaOpen ? "open" : ""}`}
                ></span>
              </a>
              <ul className={`dropdown-menu ${isMetaOpen ? "open" : ""}`}>
                <li className="" id="empty">
                  <CustomLink to="/"></CustomLink>
                </li>
                <li className="dropdow">
                  <CustomLink
                    to="/meta/locationCategory"
                    onClick={handleOtherNavClick}
                  >
                    Categories
                  </CustomLink>
                </li>
                <li className="dropdow tagss">
                  <CustomLink to="/meta/tags" onClick={handleOtherNavClick}>
                    Tags
                  </CustomLink>
                </li>
                <li className="dropdow">
                  <CustomLink
                    to="/meta/MovieLangauge"
                    onClick={handleOtherNavClick}
                  >
                    Languages
                  </CustomLink>
                </li>
              </ul>
            </li>
            <li className="sign-out" onClick={logoutHandler}>
              <a href="#" className="nav-link">
                <span className="icon">
                  <LogoutOutlinedIcon className="ion-icon" />
                </span>
                <span className="title">Sign Out</span>
              </a>
            </li>
          </ul>
        </div>
      )}
      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
          <p className=" mb-3"><b>Are you sure you want to sign out?</b></p>
            <div className="confirmation-buttons">
              <button className="cancel-button" onClick={cancelLogout}>
                Cancel
              </button>
              <button className="confirm-button" onClick={confirmLogout}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navigation;
