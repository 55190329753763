import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useParams } from "react-router-dom";
import axios from "axios";
import "react-tabs/style/react-tabs.css";
import { useLocation } from "react-router-dom";
import "./LocationTab.scss";
import LocationForm from "./LocationForm";
import MovieListByLocation from "./MoviesOnLocation";
import Breadcrumb from "../../Breadcrumb";
import Navigation from "../../component/Navigation";

const LocationTab = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const { id } = useParams();
  // console.log(id);

  const fetchMovieList =`http://3.108.152.117:8080/location/getMoviesByLocation/${id}`;

  const [selectedTab, setSelectedTab] = useState(0);
  const [onMoviesAvailable, setMoviesAvailable] = useState(false); // Initially set to false

  useEffect(() => {
    if (activeTab === "map-location") {
      setSelectedTab(1); // Set the index of the "Map Location" tab
    }

    // Fetch movie data here
    const fetchMovieData = async () => {
      try {
        const response = await axios.get(fetchMovieList); // Call your API function here using axios
        if (response.data.length > 0) {
          // Check if movies are available
          setMoviesAvailable(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // console.log("No movies mapped for this location."); // Log the message if no movies are mapped
        } else {
          console.error("Error fetching movie data:", error);
        }
      }
    };

    fetchMovieData();
  }, [activeTab]);

  return (
    <>
      <Breadcrumb />
      <Navigation />
      <div className="tablocation">
        <Tabs
          selectedIndex={selectedTab}
          onSelect={(index) => setSelectedTab(index)}
        >
          <TabList>
            <Tab>
              <b className="tabs">Location Detail</b>
            </Tab>
            {onMoviesAvailable && (
              <Tab>
                <b className="tabs">Movie Mapping</b>
              </Tab>
            )}
          </TabList>
          <div>
            <TabPanel>
              <LocationForm />
            </TabPanel>
            {onMoviesAvailable && (
              <TabPanel>
                <MovieListByLocation onMoviesAvailable={setMoviesAvailable} />
              </TabPanel>
            )}
          </div>
        </Tabs>
      </div>
    </>
  );
};

export default LocationTab;
