import React from "react";
import { Routes, Route } from "react-router-dom";
import Carousel from "../Wherewasitshot/Home/Carousel";
import MovieList from "../Wherewasitshot/Movies/MovieList";
import MovieDetail from "./Movies/MovieDetail";
import LocationList from "./Home/Locations/LocationList";
import LocationDetail from "./Home/Locations/LocationDetail";
import ExploreLocationDetail from "./Home/Locations/ExploreLocationDetail";
import ContactUs from "./ContactUs";
import Dashboard from "../Admin/pages/Dashboard/Dashboard";
import Movies from "../Admin/pages/Movies/Movies";
import MovieTab from "../Admin/pages/Movies/MovieTab";
import MapLocation from "../Admin/pages/Movies/MapLocation";
import LocationTab from "../Admin/pages/location/LocationTab";
import TagView from "../Admin/pages/Meta/TagView";
// import MovieLanguage from "../Admin/pages/Meta/MovieLanguage";
import LocationCategory from "../Admin/pages/Meta/LocationCategory";
import Location from "../Admin/pages/location/Location";
import Category from "../Admin/pages/Meta/Category";
import MoviesLocationEdit from "../Admin/pages/Movies/MoviesLocationEdit";
import { PageProvider } from "../Admin/pages/PageContext";
import GlobalSearch from "./Movies/GlobalSearch";
import SearchResult from "./Movies/SearchResult";
import MovieLanguage from "../Admin/pages/Meta/MovieLanguage";

const UserRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Carousel />} />
      <Route path="/moviesList" element={<MovieList />} />
      <Route path="/movie/:movieId" element={<MovieDetail />} />
      <Route path="/locationsList" element={<LocationList />} />
      <Route path="/location/:id" element={<LocationDetail />} />
      <Route path="/explore-location-detail/:location" element={<ExploreLocationDetail />} />
      <Route path="/contact" element={<ContactUs />} />

      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/movies" element={
      <PageProvider>
      <Movies />
      </PageProvider>} />
      <Route path="/movies" element={<MoviesLocationEdit />} />
      <Route path="/movies/add" element={<MovieTab />} />
      <Route path="/movies/updatemovie/:moviesId" element={<MovieTab />} />
      <Route path="/movies/add/map-location" element={<MapLocation />} />
      <Route path="/location/add" element={<LocationTab />} />
      <Route path="/location/Edit/:id" element={<LocationTab />} />
      <Route path="/meta/tags" element={<TagView />} />
      <Route path="/meta/MovieLangauge" element={<MovieLanguage/>} />
      <Route path="/meta/locationCategory" element={<LocationCategory />} />
      <Route path="/location" element={<Location />} />
      <Route path="/meta/locationCategory/category" element={<Category />} />
      <Route path="/global-search" element={<GlobalSearch />} />
      <Route
       path="/search-result/:query"
        element={<SearchResult />} 
      />
    </Routes>
  );
};

export default UserRoute;
