import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./LocationForm.scss";
import TagField from "../../component/TagField";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { v4 } from "uuid";

const LocationForm = () => {
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const [updateMessage, setUpdateMessage] = useState("");

  const [user, setUser] = useState({
    locationName: "",
    type: "",
    city: "",
    state: "",
    country: "",
    latitude: "",
    longitude: "",
    description: "",
    wikiLink: "",
    image: "",
    tags: "",
  });

  const {
    locationName,
    type,
    city,
    state,
    country,
    latitude,
    longitude,
    description,
    wikiLink,
  } = user;

  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [imageError, setImageError] = useState("");
  const [locationNameError, setLocationNameError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [wikiLinkError, setWikiLinkError] = useState("");
  const [latitudeError, setLatitudeError] = useState("");
  const [longitudeError, setLongitudeError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageDataList, setImageDataList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [submitted, setSubmitted] = useState(false);


  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setSelectedImage(selectedImage);

    // Display image preview
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setImagePreview("");
    }
  };

  const onInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleTags = (dataFromTagField) => {
    setTags(dataFromTagField);
    localStorage.setItem("tags", setTags);
  };
  const validateInputs = () => {
    let isValid = true;

    if (!locationName) {
      setLocationNameError("Location Name Field Is Required");
      isValid = false;
    } else {
      setLocationNameError("");
    }

    if (!type) {
      setTypeError("Type Field Is Required");
      isValid = false;
    } else {
      setTypeError("");
    }

    // if (!city) {
    //   setCityError("City Field Is Required");
    //   isValid = false;
    // } else {
    //   setCityError("");
    // }

    // if (!state) {
    //   setStateError("State Field Is Required");
    //   isValid = false;
    // } else {
    //   setStateError("");
    // }

    if (!selectedCountry) {
      setCountryError("Country Field Is Required");
      isValid = false;
    } else {
      setCountryError("");
    }

    // if (!latitude) {
    //   setLatitudeError("Latitude Field Is Required");
    //   isValid = false;
    // } else {
    //   setLatitudeError("");
    // }

    // if (!longitude) {
    //   setLongitudeError("Longitude Field Is Required");
    //   isValid = false;
    // } else {
    //   setLongitudeError("");
    // }

    if (!description) {
      setDescriptionError("Description Field Is Required");
      isValid = false;
    } else {
      setDescriptionError("");
    }

    if (!wikiLink) {
      setWikiLinkError("WikiLink Field Is Required");
      isValid = false;
    } else {
      setWikiLinkError("");
    }

    // If updating and the image exists, skip image validation
    if (!id || (id && user.image)) {
      if (!selectedImage && !user.image) {
        setImageError("Image is required");
        isValid = false;
      } else {
        setImageError("");
      }
    }

    return isValid;
  };

  useEffect(() => {
    const loadLocation = async () => {
      try {
        if (id) {
          const response = await axios.get(
            `https://wherewasitshot.com/api/location/get/${id}`
          );

          const locationData = response.data;

          // Set all fields of the user state
          setUser({
            locationName: locationData.locationName || "",
            type: locationData.type || "",
            city: locationData.city || "",
            state: locationData.state || "",
            country: locationData.country || "",
            latitude: locationData.latitude || "",
            longitude: locationData.longitude || "",
            description: locationData.description || "",
            wikiLink: locationData.wikiLink || "",
            image: locationData.image || "",
            tags: locationData.tags || [],
          });

          // Check if the locationData contains an image URL
          if (locationData.image) {
            setImagePreview(locationData.image);
          } else {
            setImagePreview(""); // Clear the image preview if no image URL is found
          }
          setTags(locationData.tags || []);
          setSelectedCountry(locationData.country || "");
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadLocation();
  }, [id]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://wherewasitshot.com/api/meta/locationcategory/get"
        );
        let categoriesData = response.data;

        // Check if response.data is not null
        if (categoriesData) {
          // Sort categories alphabetically by name
          categoriesData.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
          setCategories(categoriesData);
        } else {
          console.error("Response data is null:", response);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };


    fetchCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    // console.log("Selected Category:", selectedCategory);
    setUser((prevUser) => ({
      ...prevUser,
      type: selectedCategory,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Check if all mandatory fields are filled
    if (!validateInputs()) {
      return;
    }

    // Fetch the token from wherever you have stored it (session storage, local storage, etc.)
    const token = sessionStorage.getItem("token");

    if (id) {
      // If movieIdToUpdate has a value, it means we want to update a movie
      handleLocationUpdate(id, token);
      setUpdateMessage(`${locationName} location updated successfully.`);
    } else {
      // Otherwise, we want to upload a new movie
      // If an image is added, call the uploadImage function when the form is submitted
      if (selectedImage) {
        uploadImage(token);
      } else {
        // If no image is added, prevent submission
        ////console.error("Image is required");
      }
    }
  };

  const uploadImage = (token) => {
    if (!selectedImage) return;

    const imageRef = ref(storage, `images/${selectedImage.name + v4()}`);

    uploadBytes(imageRef, selectedImage)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((url) => {
            const postData = {
              ...user,
              image: url,
              tags: tags.map((tag) => ({
                name: tag.name,
                id: tag.id,
                description: tag.description,
              })),
              country: selectedCountry,
            };

            axios
              .post("https://wherewasitshot.com/api/location/add", postData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
              .then((response) => {
                // Log a success message
                // console.log("Server response:", response.data);

                if (response.status === 200) {
                  // Success response, location added
                  alert("Location added successfully");
                  navigate(`/location`);
                }

                // Clear form fields and state
                setImageDataList((prevList) => [...prevList, postData]);
                setUser({
                  locationName: "",
                  type: "",
                  city: "",
                  state: "",
                  country: "",
                  latitude: "",
                  longitude: "",
                  description: "",
                  wikiLink: "",
                  image: "",
                  tags: "",
                });
                setTags([]);
                setSelectedImage(null);
                setImagePreview("");
              })
              .catch((error) => {
                // Handle Axios errors
                if (error.response && error.response.status === 400) {
                  // Location name already exists
                  alert("Location already exists, cannot add.");
                }
                // Handle token expiration
                if (error.response.status === 403) {
                  alert("Token expired. Redirecting to /admin");
                  navigate('/admin');
                }
              });
          })
      })
      .catch((error) => {
        ////console.error("Error uploading image:", error);
      });
  };


  const handleLocationUpdate = (id, token) => {
    // If a new image is selected, upload the new image and update location data
    if (selectedImage) {
      const imageRef = ref(storage, `images/${selectedImage.name + v4()}`);

      uploadBytes(imageRef, selectedImage)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              const updatedData = {
                ...user,
                image: url,
                tags: tags.map((tag) => ({
                  name: tag.name,
                  id: tag.id,
                  description: tag.description,
                })),
                // Explicitly set the 'country' property in 'updatedData'
                country: selectedCountry,
              };

              // Make a PUT request to update the location data
              axios
                .put(`https://wherewasitshot.com/api/location/update/${id}`, updatedData,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                .then((response) => {
                  // Log a success message
                  alert(`${locationName} location updated successfully.`);
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    alert("Token expired. Redirecting to /admin");
                    navigate('/admin');
                  }
                });
            })
            .catch((error) => {
              ////console.error("Error fetching download URL:", error);
            });
        })
        .catch((error) => {
          ////console.error("Error uploading image:", error);
        });
    } else {
      // If no new image is selected, update the location data without changing the image
      const updatedData = {
        ...user,
        tags: tags.map((tag) => ({
          name: tag.name,
          id: tag.id,
          description: tag.description,
        })),
        // Explicitly set the 'country' property in 'updatedData'
        country: selectedCountry,
      };

      // Make a PUT request to update the location data
      axios
        .put(`https://wherewasitshot.com/api/location/update/${id}`, updatedData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        .then((response) => {
          // console.log(`Location with ID ${id} updated successfully.`);
          // console.log(response.data);
          // You can handle the response as needed (e.g., show a success message)
          // alert(`${locationName} location updated successfully.`)
          // Navigate to /location after the update
          // navigate(`/location`);
        })
        .catch((error) => {
          ////console.error(`Error updating location with ID ${id}:`, error);
          // Handle token expiration
          if (error.response.status === 403) {
            alert("Token expired. Redirecting to /admin");
            navigate('/admin');
          }
        });
    }
  };


  return (
    <div className="container-lf" id="formmm">
      <div className="row1" id="locationform">
        <div className="border  p-4  shadow locationtable">
          <h5 className="text-center mb-4">
            {id ? "Edit SpotAdder" : "Spot Adder"}
            {id && (
              <EditLocationAltIcon
                style={{
                  marginLeft: "0.2em",
                  marginBottom: "0.2em",
                  verticalAlign: "middle",
                  fontSize: "22px",
                  color: "blue",
                }}
              />
            )}
          </h5>
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className=" col-6 mb-3">
                <label htmlFor="LocationName" className="form-label">
                  <b>Name*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="locationName"
                  name="locationName"
                  value={locationName}
                  onKeyDown={onInputKeyPress}
                  onChange={(e) =>
                    setUser({ ...user, locationName: e.target.value })
                  }
                />
                <div className="text-danger">
                  <small>
                    {locationNameError ? `*${locationNameError}` : ""}
                  </small>
                </div>
              </div>

              <div className="col-6 mb-3">
                <label htmlFor="Type" className="form-label">
                  <b>Category*</b>
                </label>
                <select
                  className="form-control"
                  id="type"
                  // id={type}
                  name="type"
                  value={type}
                  onChange={handleCategoryChange}
                  onKeyDown={onInputKeyPress}
                >
                  {/* <option value="">Select a category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select> */}
                  <option value="">Select a category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <div className="text-danger">
                  <small>{typeError ? `*${typeError}` : ""}</small>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 mb-3">
                <label htmlFor="latitude" className="form-label">
                  <b>Latitude</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="latitude"
                  name="latitude"
                  value={latitude}
                  onChange={(e) =>
                    setUser({ ...user, latitude: e.target.value })
                  }
                  onKeyDown={onInputKeyPress}
                />
                {/* <div className="text-danger">
                  <small>{latitudeError ? `*${latitudeError}` : ""}</small>
                </div> */}
              </div>
              <div className="col-6 mb-3">
                <label htmlFor="longitude" className="form-label">
                  <b>Longitude</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="longitude"
                  name="longitude"
                  value={longitude}
                  onChange={(e) =>
                    setUser({ ...user, longitude: e.target.value })
                  }
                  onKeyDown={onInputKeyPress}
                />
                {/* <div className="text-danger">
                  <small>{longitudeError ? `*${longitudeError}` : ""}</small>
                </div> */}
              </div>
            </div>

            <div className="row">
              <div className="col-6 mb-3">
                <label htmlFor="city" className="form-label">
                  <b>City</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  value={city}
                  onChange={(e) => setUser({ ...user, city: e.target.value })}
                  onKeyDown={onInputKeyPress}
                />

                {/* <div className="text-danger">
                  <small>{cityError ? `*${cityError}` : ""}</small>
                </div> */}
              </div>
              <div className="col-6 mb-3">
                <label htmlFor="state" className="form-label">
                  {" "}
                  <b>Country / State</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  name="state"
                  value={state}
                  onChange={(e) => setUser({ ...user, state: e.target.value })}
                  onKeyDown={onInputKeyPress}
                />
                {/* <div className="text-danger">
                  <small>{stateError ? `*${stateError}` : ""}</small>
                </div> */}
              </div>
            </div>

            <div className="row">
              <div className="col-6 mb-3">
                <label htmlFor="country" className="form-label">
                  <b>Location Preference*</b>
                </label>
                <select
                  className="form-select"
                  id="country"
                  name="country"
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  <option value="">Select a location</option>
                  <option value="India">India</option>
                  <option value="Australia">Australia</option>
                  <option value="Africa">Africa</option>
                  <option value="Asia">Asia</option>
                  <option value="Americas">Americas</option>
                  <option value="Europe">Europe</option>
                </select>
                <div className="text-danger">
                  <small>{countryError ? `*${countryError}` : ""}</small>
                </div>
              </div>
              <div className="col-6 mb-3">
                <label htmlFor="wikiLink" className="form-label">
                  {" "}
                  <b>WikiLink*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="wikiLink"
                  name="wikiLink"
                  value={wikiLink}
                  onChange={(e) =>
                    setUser({ ...user, wikiLink: e.target.value })
                  }
                  onKeyDown={onInputKeyPress}
                />

                <div className="text-danger">
                  <small>{wikiLinkError ? `*${wikiLinkError}` : ""}</small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <label htmlFor="Description" className="form-label">
                  <b> Description*</b>
                </label>
                <textarea
                  type="textarea"
                  className="form-control"
                  id="description"
                  name="description"
                  value={description}
                  onChange={(e) =>
                    setUser({ ...user, description: e.target.value })
                  }
                  onKeyDown={onInputKeyPress}
                />
                <div className="text-danger">
                  <small>
                    {descriptionError ? `*${descriptionError}` : ""}
                  </small>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col-md-6">
                <label htmlFor="image" className="form-label">
                  <b>Image*</b>
                </label>
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  className="form-control"
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <div className="mb-3">
                    <img
                      src={imagePreview}
                      alt="Image Preview"
                      className="img-thumbnail"
                      width="200"
                    />
                  </div>
                )}
                {imageError && (
                  <div className="text-danger">
                    <small>{imageError}</small>
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <TagField
                  fetchedTags={tags}
                  sendDataToLocationForm={handleTags}
                />
              </div>
            </div>

            {updateMessage && (
              <div className="alert alert-success d-flex align-items-center justify-content-between" role="alert">
                <span>{updateMessage}</span>
                <Link to="/location" className="btn-close" aria-label="Close" onClick={() => setUpdateMessage("")}></Link>
              </div>
            )}

            {id ? (
              <button type="submit" className="btn btn-outline-primary m-2 ">
                Update
              </button>
            ) : (
              <button type="submit" className="btn btn-outline-primary m-2">
                Submit
              </button>
            )}

            <Link className="btn btn-outline-danger mx-2" to="/location">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LocationForm;