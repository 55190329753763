import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./MovieOnLocation.scss";

const MovieListByLocation = () => {
  const { id } = useParams();
  const [movies, setMovies] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://wherewasitshot.com/api/location/getMoviesByLocation/${id}`
        );
        if (response.data.length > 0) {
          setMovies(response.data);
        } else {
          setError("No movies found for this location.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching movies.");
      }
    };

    fetchData();
  }, [id]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Check for duplicate movieId values
  const uniqueMovies = Array.from(new Set(movies.map(movie => movie.movieId))).map(movieId => {
    return movies.find(movie => movie.movieId === movieId);
  });

  return (
    <div className="container">
      <div className="movie-list">
        <div className="row w-100">
          {uniqueMovies.map((movie) => (
            <div key={movie.movieId} className="col-sm-4 mb-4" style={{ minHeight: "350px" }}>
              <div className="movie-item card">
                <img
                  className="filmImg w-100"
                  src={movie.image}
                  alt={movie.movieName}
                  style={{ maxWidth: "230px", maxHeight: "300px" }}
                />
                <div className="movie-name text-center">{movie.movieName}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MovieListByLocation;
