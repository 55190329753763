import React, { useState, useEffect } from "react";
import "./TagView.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TagTopbar from "../../component/TagTopbar";
import Pagination from "../../component/Pagination";
import Breadcrumb from "../../Breadcrumb";
import Navigation from "../../component/Navigation";
import { useNavigate } from "react-router-dom";


const TagView = () => {
  const [tagValues, setTagValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newTagName, setNewTagName] = useState("");
  //   const [newTagDescription, setNewTagDescription] = useState("");
  const [editTagId, setEditTagId] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  // const [deleteTagId, setDeleteTagId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 10;
  const navigate = useNavigate();


  const fetchTagValues = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://wherewasitshot.com/api/meta/tag/get");
      const data = await response.json();
      // Filter out tags with null or undefined names
      const validTags = data.filter((tag) => tag.name !== null && tag.name !== undefined);
      setTagValues(validTags);
    } catch (error) {
      console.error("Error fetching tag values:", error);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fetchTagValues();
  }, [searchQuery, currentPage]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const filteredTags = tagValues.filter((tag) =>
    tag.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredTags.length / itemsPerPage);
  const pageRange = 10;
  const halfRange = Math.floor(pageRange / 2);
  let startPage = Math.max(1, currentPage - halfRange);
  let endPage = Math.min(startPage + pageRange - 1, totalPages);

  if (endPage - startPage < pageRange - 1) {
    startPage = Math.max(1, endPage - pageRange + 1);
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTags = filteredTags.slice(indexOfFirstItem, indexOfLastItem);

  const handleAddTagClick = () => {
    setShowAddForm(!showAddForm);
    setNewTagName("");
    // setNewTagDescription("");
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const token = sessionStorage.getItem("token");

      if (!token) {
        console.error("Authorization token is missing. Please log in.");
        return;
      }

      const response = await fetch("https://wherewasitshot.com/api/meta/tag/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: newTagName,
          //   description: newTagDescription,
        }),
      });

      if (response.ok) {
        fetchTagValues();
        setShowAddForm(false);
        setNewTagName("");
        // setNewTagDescription("");
      } else if (response.status === 403) {
        alert("Token expired. Redirecting to /admin");
        navigate('/admin');
      } else {
        console.error("Error adding tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding tag:", error);
    }
  };

  const handleEditClick = (tagId) => {
    setEditTagId(tagId);
    const tagToEdit = tagValues.find((tag) => tag.id === tagId);
    if (tagToEdit) {
      setNewTagName(tagToEdit.name);
      //   setNewTagDescription(tagToEdit.description);
    }
  };

  const handleCancelEdit = () => {
    setEditTagId(null);
    setNewTagName("");
    // setNewTagDescription("");
  };

  const handleUpdateTag = async () => {
    try {
      const token = sessionStorage.getItem("token");

      if (!token) {
        console.error("Authorization token is missing. Please log in.");
        return;
      }

      const response = await fetch(
        `https://wherewasitshot.com/api/meta/tagupdate/${editTagId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: newTagName,
            // description: newTagDescription,
          }),
        }
      );

      if (response.ok) {
        fetchTagValues();
        setEditTagId(null);
        setNewTagName("");
      } else if (response.status === 403) {
        alert("Token expired. Redirecting to /admin");
        navigate('/admin');
      } else {
        console.error("Error updating tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating tag:", error);

    }
  };

  const handleDeleteTag = (tagId) => {
    setShowConfirmation(tagId); // Pass the tagId to show confirmation popup
  };

  const confirmDeleteTag = async (tagId) => {
    try {
      const token = sessionStorage.getItem("token");

      const response = await fetch(`https://wherewasitshot.com/api/meta/tagDelete/${tagId}`, { // Pass the correct tagId
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        fetchTagValues();
        setShowConfirmation(false); // Hide confirmation popup after deletion
      }
      else if (response.status === 403) {
        setShowConfirmation(false);
        alert("This tag is already mapped you cannot delete it");
      }
      else {
        console.error(`Error deleting tag:`, response.statusText);
      }
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };


  // const handleDeleteTag = (tagId) => {

  //   setShowConfirmation(true);
  // };

  // const confirmDeleteTag = async () => {
  //   try {
  //     const token = sessionStorage.getItem("token");

  //     const response = await fetch(`https://wherewasitshot.com/api/meta/tagDelete/${showConfirmation}`, {
  //       method: "DELETE",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (response.ok) {
  //       fetchTagValues();
  //       setShowConfirmation(null);

  //     }
  //     // else if (response.status === 403) {
  //     //   alert("Token expired. Redirecting to /admin");
  //     //   navigate('/admin');
  //     // } 
  //     else {
  //       console.error(`Error deleting tag:`, response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting tag:", error);
  //   }
  // };

  return (
    <>
      <Breadcrumb />
      <Navigation />

      <div className="container-tag">
        <div className="row justify-content-center">
          <TagTopbar onSearchInputChange={handleSearchInputChange} />
          <div className="col-md-6 tagform">
            <div className="row col-12">
              <div className="add-tag-button">
                {showAddForm ? (
                  <button
                    className="btn addtagcancel btn-danger"
                    onClick={handleAddTagClick}
                  >
                    CANCEL
                  </button>
                ) : (
                  <button
                    className="btn addtag btn-primary"
                    onClick={handleAddTagClick}
                  >
                    ADD TAGS
                  </button>
                )}
              </div>
            </div>

            {showAddForm && (
              <div className="mt-5">
                <form onSubmit={handleFormSubmit}>
                  <div className="form-group">
                    <label htmlFor="newTagName"><h6>Tag Name</h6></label>
                    <input
                      type="text"
                      className="form-control"
                      id="newTagName"
                      value={newTagName}
                      onChange={(e) => setNewTagName(e.target.value)}
                      required
                    />
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="newTagDescription">Tag Description</label>
                    <textarea
                      className="form-control"
                      id="newTagDescription"
                      rows="3"
                      value={newTagDescription}
                      onChange={(e) => setNewTagDescription(e.target.value)}
                      required
                    />
                  </div> */}
                  <button
                    type="submit"
                    className="btn btn-primary m-2 addTagbtn"
                  >
                    ADD
                  </button>
                </form>
              </div>
            )}

            <div className="mt-3">
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <div className="mt-5 tablee">
                  <table className="table table-striped table-hover shadow table-responsive">
                    <thead className="">
                      <tr>
                        <th className="id">#</th>
                        <th className="tagss">Tag</th>
                        <th className="action">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTags.map((tag, index) => (
                        <tr key={tag.id}>
                          <td className="id">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                          <td className="tagss">
                            {editTagId === tag.id ? (
                              <input
                                className="input"
                                type="text"
                                value={newTagName}
                                onChange={(e) => setNewTagName(e.target.value)}
                              />
                            ) : (
                              tag.name
                            )}
                          </td>
                          <td>
                            {editTagId === tag.id ? (
                              <>
                                <button
                                  className="btn btn-outline-primary "
                                  onClick={handleUpdateTag}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-outline-danger "
                                  onClick={handleCancelEdit}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <div className="button12">
                                <button
                                  className="button"
                                  onClick={() => handleEditClick(tag.id)}
                                >
                                  <EditIcon className="EditIcon" />{" "}
                                </button>
                                <button
                                  className="button"
                                  onClick={() => handleDeleteTag(tag.id)}
                                >
                                  <DeleteIcon className="DeleteIcon" />
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                </div>
              )}
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              prevPage={prevPage}
              nextPage={nextPage}
              paginate={paginate}
              startPage={startPage}
              endPage={endPage}
            />

            {showConfirmation && (
              <div className="confirmation-popup">
                <div className="confirmation-popup-content">
                  <h3>Confirmation</h3>
                  <p>Are you sure you want to delete this tag?</p>
                  <div className="confirmation-buttons">
                    <button
                      className="cancel-button"
                      onClick={() => setShowConfirmation(false)}
                    >
                      Cancel
                    </button>
                    {/* <button
                      className="confirm-button"
                      onClick={confirmDeleteTag}
                    >
                      Delete
                    </button> */}

                    <button
                      className="confirm-button"
                      onClick={() => confirmDeleteTag(showConfirmation)}
                    >
                      Delete
                    </button>

                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TagView;
