import React from "react";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import "./Pagination.scss";

const Pagination = ({
  currentPage,
  totalPages,
  prevPage,
  nextPage,
  paginate,
  startPage,
  endPage,
}) => {
  return (
    <section className="pagination_container">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button className="page-link" onClick={prevPage}>
            <SkipPreviousIcon />
          </button>
        </li>
        {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
          <li
            key={startPage + index}
            className={`page-item ${
              currentPage === startPage + index ? "active" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => paginate(startPage + index)}
            >
              {startPage + index}
            </button>
          </li>
        ))}
        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <button className="page-link" onClick={nextPage}>
            <SkipNextIcon />
          </button>
        </li>
      </ul>
    </section>
  );
};

export default Pagination;




// import React, { useState } from "react";
// import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
// import SkipNextIcon from "@mui/icons-material/SkipNext";
// import "./Pagination.scss";

// const Pagination = ({
//   currentPage,
//   totalPages,
//   prevPage,
//   nextPage,
//   paginate,
// }) => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//     paginate(1);
//   };

//   const filteredPageNumbers = pageNumbers.filter((number) =>
//     number.toString().includes(searchTerm)
//   );

//   // Calculate the start and end index for the displayed page numbers
//   const startIndex = (currentPage - 1) * 10;
//   const endIndex = startIndex + 10;
//   const displayedPageNumbers = filteredPageNumbers.slice(startIndex, endIndex);

//   return (
//     <section className="pagination_container">
//       <div className="search-container">
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchTerm}
//           onChange={handleSearch}
//         />
//       </div>
//       <ul className="pagination">
//         <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
//           <button className="page-link" onClick={prevPage}>
//             <SkipPreviousIcon />
//           </button>
//         </li>
//         {displayedPageNumbers.map((number) => (
//           <li
//             key={number}
//             className={`page-item ${currentPage === number ? "active" : ""}`}
//           >
//             <button className="page-link" onClick={() => paginate(number)}>
//               {number}
//             </button>
//           </li>
//         ))}
//         <li
//           className={`page-item ${
//             currentPage === totalPages ? "disabled" : ""
//           }`}
//         >
//           <button className="page-link" onClick={nextPage}>
//             <SkipNextIcon />
//           </button>
//         </li>
//       </ul>
//     </section>
//   );
// };

// export default Pagination;