import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./TopbarLocation.scss";
import { Link } from "react-router-dom";

const TopbarLocation = ({ onSearchInputChange }) => {
  return (
    <div className="toploc">
      <div className="main">
        <div className="topbar">
          <div id="toggle"></div>

          <div className="search">
            <label>
              <input
                type="text"
                placeholder="Search here"
                onChange={onSearchInputChange}
              />
              <SearchIcon id="search_icon" />
            </label>
          </div>

          <div className="add-Location-button">
            <Link className="btn addlocation" to="/location/add">
              ADD LOCATION
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopbarLocation;
