import React from "react";
import "./Footer.scss";
import EmailIcon from "@mui/icons-material/Email";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from '@mui/icons-material/Twitter';

export default function Footer() {
  return (
    <footer className="bg-light text-center text-lg-start">
      <section className="footer">
        <div className="container text-md-start">
          <div className="row mt-3 col-md-12">
            <div className="col-md-8 append">
              <div className="downcontent">
                © Where Was It Shot 2024, Chennai, India
              </div>

              <p>
                Powered by <b>DataPattern</b>
              </p>
            </div>
            <div className="col-md-2 footerleft">
              <p className="text_content">
                <a
                  href="mailto: wherewasitshot@gmail.com"
                  className="text-reset text-decoration-none"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fas fa-envelope "></i>
                  <EmailIcon className="mail" />
                </a>
              </p>

              <p className="text_content lower">
                <a
                  href="tel:+14258942255"
                  className="text-reset text-decoration-none"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fas fa-phone "></i>{" "}
                  <AddIcCallIcon className="phone" />
                </a>
              </p>

              <p className="text_content lower">
                <a
                  href="https://www.facebook.com/wherewasitshot?sfnsn=wiwspwa&mibextid=2JQ9oc"
                  className="text-reset text-decoration-none"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook"></i>{" "}
                  <FacebookRoundedIcon className="facebook" />
                </a>
              </p>

              <p className="text_content lower">
                <a
                  href="https://x.com/wherewasitshot?s=20"
                  className="text-reset text-decoration-none"
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className="fab fa-twitter"></i>{" "}
                  <TwitterIcon className="twitter" />
                </a>
              </p>

            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}
