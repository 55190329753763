import React, { useEffect, useState } from "react";
import "./location.scss";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import TopbarLocation from "../../component/TopbarLocation";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Pagination from "../../component/Pagination";
import Breadcrumb from "../../Breadcrumb";
import Navigation from "../../component/Navigation";

const Location = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 10;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { locationId } = useParams();

  useEffect(() => {
    loadUsers();
    updateBreadcrumbs();
  }, [locationId, searchQuery, currentPage]);

  useEffect(() => {
    loadUsers();
  }, []); // Empty dependency array to run only once on mount

  const loadUsers = async () => {
    try {
      const result = await axios.get("https://wherewasitshot.com/api/location/getList");
      setUsers(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const deleteLocation = async (id, locationName) => {
    setDeleteId(id);
    setShowConfirmation(true);
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
    setDeleteId(null);
  };

  const confirmDelete = async () => {
    try {
      const token = sessionStorage.getItem("token");

      await axios.delete(`https://wherewasitshot.com/api/location/delete/${deleteId}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShowConfirmation(false);
      setDeleteId(null);
      loadUsers();
    } catch (error) {
      console.error(error);
    }
  };

  const updateBreadcrumbs = () => {
    const currentLocation = users.find(
      (user) => user.id === parseInt(locationId)
    );
    if (currentLocation) {
      // console.log("Breadcrumbs:", currentLocation.locationname); // Just for debugging
    }
  };

  // const totalPages = Math.ceil(users.length / itemsPerPage);
  const totalPages = Math.ceil(
    users
      .filter((user) =>
        user &&
        user.locationName &&
        user.locationName.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .length / itemsPerPage
  );
  
  const pageRange = 10;
  const halfRange = Math.floor(pageRange / 2);
  let startPage = Math.max(1, currentPage - halfRange);
  let endPage = Math.min(startPage + pageRange - 1, totalPages);
  

  if (endPage - startPage < pageRange - 1) {
    startPage = Math.max(1, endPage - pageRange + 1);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage + 1;
  const currentItems = users
  .filter((user) =>
    user &&
    user.locationName &&
    user.locationName.toLowerCase().includes(searchQuery.toLowerCase())
  )
  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  
  return (
    <>
      <Breadcrumb />
      <Navigation />

      <TopbarLocation onSearchInputChange={handleSearchInputChange} />
      <div className="form">
        <div className="loc">
          <div className="py-3">
            <table className="table table-striped table-hover shadow table-responsive">
              <thead>
                <tr id="table_head">
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Category</th>
                  <th scope="col">Continent</th>
                  <th scope="col">Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((user, index) => (
                  <tr key={user.id}>
                    {/* <td scope="row">{index + 1}</td> */}
                    <td scope="row">{indexOfFirstItem + index }</td>
                    <td>{user.locationName}</td>
                    <td>{user.type}</td>
                    <td>{user.country}</td>
                    <td>{user.lastUpdatedDate}</td>
                    <td>
                      <div className="rowButton" id="btnIcon">
                        <Link
                          className="btnIcon"
                          to={`/location/Edit/${user.id}`}
                        >
                          <EditIcon className="EditIcon" />
                        </Link>
                        <button
                          className="btnIconD"
                          onClick={() => deleteLocation(user.id)}
                        >
                          <DeleteIcon className="DeleteIcon" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              prevPage={prevPage}
              nextPage={nextPage}
              paginate={paginate}
              startPage={startPage}
              endPage={endPage}
            />
          </div>

          {showConfirmation && (
            <div className="confirmation-popup">
              <div className="confirmation-popup-content">
                <h3>Confirmation</h3>
                <p>
                  You want to delete this location:{" "}
                  <strong>
                    {
                      users.find((location) => location.id === deleteId)
                        ?.locationName
                    }
                  </strong>
                  ?
                </p>
                <div className="confirmation-buttons">
                  <button className="cancel-button" onClick={cancelDelete}>
                    Cancel
                  </button>
                  <button className="confirm-button" onClick={confirmDelete}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Location;