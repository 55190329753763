import React, { useEffect, useState } from "react";
import "./Movies.scss";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Topbar from "../../component/Topbar";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Pagination from "../../component/Pagination";
import Breadcrumb from "../../Breadcrumb";
import Navigation from "../../component/Navigation";
import  { PageProvider }from "../PageContext";

const Movies = () => {
  const [movies, setMovies] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 10;

  const { movieId } = useParams();

  useEffect(() => {
    loadMovieAndSongsData();
    updateBreadcrumbs();
  }, [movieId, searchQuery, currentPage]);

  const loadMovieAndSongsData = async () => {
    try {
      const movieResponse = await axios.get(
        "https://wherewasitshot.com/api/movie/retrieveall"
      );

      const filteredMovies = (movieResponse.data || []).filter(
        (movie) =>
          movie.movieName &&
          movie.movieName.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setMovies(filteredMovies);
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const deleteMovie = async (movieId, movieName) => {
    setDeleteId(movieId);
    setShowConfirmation(true);
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
    setDeleteId(null);
  };

  const confirmDelete = async () => {
    try {
      const token = sessionStorage.getItem("token");
      await axios.delete(`https://wherewasitshot.com/api/movie/delete/${deleteId}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
      setShowConfirmation(false);
      setDeleteId(null);
      loadMovieAndSongsData();
    } catch (error) {
      console.error(error);
    }
  };

  const updateBreadcrumbs = () => {
    const currentMovie = movies.find(
      (movie) => movie.movieId === parseInt(movieId)
    );
    if (currentMovie) {
      setBreadcrumbs([
        { title: "Home", url: "/" },
        { title: "Movies", url: "/movies" },
        { title: currentMovie.movieName, url: `/movies/updatemovie` },
      ]);
    }
  };

  const totalPages = Math.ceil(movies.length / itemsPerPage);
  const pageRange = 10;
  const halfRange = Math.floor(pageRange / 2);
  let startPage = Math.max(1, currentPage - halfRange);
  let endPage = Math.min(startPage + pageRange - 1, totalPages);

  if (endPage - startPage < pageRange - 1) {
    startPage = Math.max(1, endPage - pageRange + 1);
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = movies.slice(indexOfFirstItem, indexOfLastItem);

  const getAdjustedIndex = (index) => {
    return (currentPage - 1) * itemsPerPage + index + 1;
  };

  return (
    <PageProvider>
      <div id="sss">
        <Breadcrumb />
        <Navigation />
        <Topbar onSearchInputChange={handleSearchInputChange} />
        <div className="container1 mov ">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {breadcrumbs.map((breadcrumb, index) => (
                <li key={index} className="breadcrumb-item">
                  {index === breadcrumbs.length - 1 ? (
                    <span>{breadcrumb.title}</span>
                  ) : (
                    <Link to={breadcrumb.url}>{breadcrumb.title}</Link>
                  )}
                </li>
              ))}
            </ol>
          </nav>
          <div className="py-3">
            <table className="table table-striped table-hover shadow table-responsive">
              <thead>
                <tr id="table_head">
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th scope="col">Year</th>
                  <th scope="col">Language</th>
                  <th scope="col">Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((movie, index) => (
                  <tr key={movie.movieId}>
                    <td scope="row">{getAdjustedIndex(index)}</td>
                    <td>{movie.movieName}</td>
                    <td>{movie.releasedYear}</td>
                    <td>{movie.language}</td>
                    <td>{movie.lastUpdatedDate}</td>
                    <td>
                      <div className="rowButton" id="btnIcon">
                        <Link
                          className="btnIcon"
                          to={`/movies/updatemovie/${movie.movieId}`}
                        >
                          <EditIcon className="EditIcon" />
                        </Link>
                        <button
                          className="btnIconD"
                          onClick={() =>
                            deleteMovie(movie.movieId, movie.movieName)
                          }
                        >
                          <DeleteIcon className="DeleteIcon" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              prevPage={prevPage}
              nextPage={nextPage}
              paginate={paginate}
              startPage={startPage}
              endPage={endPage}
            />
          </div>

          {showConfirmation && (
            <div className="confirmation-popup">
              <div className="confirmation-popup-content">
                <h3>Confirmation</h3>
                <p>
                  You want to delete this movie:{" "}
                  <strong>
                    {
                      movies.find((movie) => movie.movieId === deleteId)
                        ?.movieName
                    }
                  </strong>
                  ?
                </p>
                <div className="confirmation-buttons">
                  <button className="cancel-button" onClick={cancelDelete}>
                    Cancel
                  </button>
                  <button className="confirm-button" onClick={confirmDelete}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageProvider>
  );
};

export default Movies;
