import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MovieForm from "./MovieForm";
import MapLocation from "./MapLocation";
import { useLocation, useParams } from "react-router-dom";
import "./MovieTab.scss";
import MoviesLocationEdit from "./MoviesLocationEdit";
import Breadcrumb from "../../Breadcrumb";
import Navigation from "../../component/Navigation";

const MovieTab = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const { moviesId } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (activeTab === "map-location") {
      setSelectedTab(1); // Set the index of the "Map Location" tab
    }
  }, [activeTab]);

  return (
    <>
      <Breadcrumb />
      <Navigation />
      <div className="tabmovie">
        <Tabs
          selectedIndex={selectedTab}
          onSelect={(index) => setSelectedTab(index)}
        >
          <TabList>
            <Tab>
              <b className="tabsss">Movie Detail</b>
            </Tab>
            <Tab>
              <b className="tabsss">Location Mapping</b>
            </Tab>
          </TabList>
          <div>
            <TabPanel>
              <MovieForm />
            </TabPanel>
            <TabPanel>
              {moviesId ? <MoviesLocationEdit /> : <MapLocation />}
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </>
  );
};

export default MovieTab;
