import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './SearchResult.scss';
import Navbar from '../Home/Navbar/Navbar';

const truncateDescription = (description, wordLimit) => {
  const words = description.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  } else {
    return description;
  }
};

const SearchResult = () => {
  const { query } = useParams();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://wherewasitshot.com/api/meta/search?query=${query}`);
        const data = await response.json();
        setResults(data);
      } catch (error) {
        console.error('Error fetching search result data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [query]);

  return (
    <>
    <p className="text-center resultData">Search results for: {query}</p>
    <div className="SearchedResult">
      <Navbar />
      <div className='searchResult '>
        

        {!loading && results.length > 0 ? (
          <div className='container'>
            {results.map((result) => (
              <Link key={result.id} to={result.movieId ? `/movie/${result.movieId}` : `/location/${result.id}`}>
                <div className="result-details">

                  <img src={result.image} alt={`Poster for ${result.movieName || result.locationName}`} className="movie-poster" />
                  <div className="details">
                    <p className='resultHead'>{result.movieName ? result.movieName.toUpperCase() : result.locationName.toUpperCase()}</p>
                    {result.locationName && <p className='resultHead'></p>}
                    <p className='resultPara'>{truncateDescription(result.description, 85)}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : !loading &&(
          <div className="noResultsContainer">
            {!loading && <p className='notfound'>No results found</p>}
            {!loading && <p>Try refining your search or check back later.</p>}
          </div>
        )}
      </div>
      </div>
    </>
  );
};

export default SearchResult;
