import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./TagField.scss";

const TagField = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (props.fetchedTags) {
      setSelectedTags(props.fetchedTags);
    }
  }, [props.fetchedTags]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setSuggestions([]);
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("Authorization token is missing. Please log in.");
      return;
    }

    axios
      .get(`https://wherewasitshot.com/api/meta/tag/search?name=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (Array.isArray(data)) {
          setSuggestions(data);
        } else {
          console.error("API response is not an array:", data);
        }
      })
      .catch((error) => console.error("Error fetching suggestions:", error));
  }, [searchQuery]);

  const handleRemoveTag = (tagToRemove) => {
    const updatedSelectedTags = selectedTags.filter(
      (tag) => tag.id !== tagToRemove.id
    );
    setSelectedTags(updatedSelectedTags);

    // Send the updated selected tags to the parent component
    props.sendDataToLocationForm(updatedSelectedTags);
  };

  const handleAddTag = (tag) => {
    // Check if the tag is not already selected
    if (!selectedTags.some((selectedTag) => selectedTag.id === tag.id)) {
      const newSelectedTags = [...selectedTags, tag];
      setSelectedTags(newSelectedTags);
      setSearchQuery("");
      setSuggestions([]);

      // Send the complete tag object to the parent component
      props.sendDataToLocationForm(newSelectedTags);
    }
  };

  const handleAddButtonClick = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    if (searchQuery.trim() !== "") {
      // Check if the tag is not already selected
      if (!selectedTags.some((selectedTag) => selectedTag.name === searchQuery)) {
        const token = sessionStorage.getItem("token");

        if (!token) {
          console.error("Authorization token is missing. Please log in.");
          return;
        }

        axios
          .get(`https://wherewasitshot.com/api/meta/tag/search?name=${searchQuery}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const existingTag = response.data[0]; // Assuming there is at most one tag with the given name

            if (existingTag) {
              // Use the existing tag from the database
              handleAddTag(existingTag);
            } else {
              // Add the new tag to the database and use it
              axios
                .post(
                  `https://wherewasitshot.com/api/meta/tag/add`,
                  { name: searchQuery },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  const newTag = {
                    id: response.data.id,
                    name: searchQuery,
                    description: "",
                  };
                  handleAddTag(newTag);
                })
                .catch((error) => console.error("Error adding tag:", error));
            }
          })
          .catch((error) => console.error("Error fetching tag:", error));
      }
    }
  };

  return (
    <div className="tag-container">
      <div className="tag-field">
        <div className="row">
          <div className="col-md-10">
            <input
              type="text"
              placeholder="Search for tags..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <button className="add-button" onClick={handleAddButtonClick}>
              Add
            </button>
          </div>
        </div>
        {suggestions && suggestions.length > 0 && (
          <div className="suggestions">
            {suggestions.map((suggestion) => (
              <div
                key={suggestion.id}
                className="suggestion"
                onClick={() => handleAddTag(suggestion)}
              >
                {suggestion.name}
              </div>
            ))}
          </div>
        )}
        {selectedTags && selectedTags.length > 0 ? (
          <div className="selected-tags">
            <div className="selected-tags-container">
              {selectedTags.map((tag) => (
                <div key={tag.id} className="selected-tag">
                  {tag.name}
                  <button
                    className="remove-button"
                    onClick={() => handleRemoveTag(tag)}
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>No tags selected.</div>
        )}
      </div>
    </div>
  );
};

export default TagField;
