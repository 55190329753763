import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import PortraitIcon from "@mui/icons-material/Portrait";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Link, useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import Breadcrumb from "../../Breadcrumb";
import Navigation from "../../component/Navigation";

const API_URL = "https://wherewasitshot.com/api/meta/count";

async function fetchData(url, setterFunction, token, navigate) {
  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // Log the response status
    // console.log("Response status:", response.status);

    // Check if the response is successful (status code 200)
    if (response.ok) {
      const data = await response.json();
      setterFunction(data);
    } else if (response.status === 403) {
      // Handle the case when the token expires (403 Forbidden)
      // console.log("Token expired. Redirecting to /admin");
      alert("Unauthorized access (Token expired)");
      navigate('/admin'); // Redirect to the /admin route
    } else {
      // Handle other server errors
      console.error("Error fetching data: Server returned an error");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    // Log the specific error message if it's related to token expiration
    if (error.response && error.response.status === 403) {
      // console.log("Token expired. Redirecting to /admin ----> Dashboard");
      // alert("Unauthorized access (Token expired)");
      navigate('/admin'); // Redirect to the /admin route
    }
  }
}

const Dashboard = () => {
  const [movieCount, setMovieCount] = useState(0);
  const [locationCount, setLocationCount] = useState(0);
  const [songCount, setSongCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      // User is not authenticated, redirect to sign-in
      navigate('/admin');
      return;
    }

    fetchData(API_URL, (data) => {
      setMovieCount(data.movieCount);
      setLocationCount(data.locationCount);
      setSongCount(data.songsAndScenesCount);
    }, token, navigate);
  }, [navigate]);

  return (
    <div>
      <Breadcrumb />
      <Navigation />

      <div className="dashboardHome">
        <div className="Box">
          <Link to={`/movies`} className="">
            <div className="cards">
              <div className="number">
                <CountUp end={movieCount} duration={2} separator="," />
                <div className="cardNames">Movies</div>
              </div>
              <div className="iconBx">
                <TheaterComedyIcon className="cardicon" />
              </div>
            </div>
          </Link>

          <Link to={`/location`} className="">
            <div className="cards">
              <div className="number">
                <CountUp end={locationCount} duration={2} separator="," />
                <div className="cardNames">Locations</div>
              </div>
              <div className="iconBx">
                <AssistantDirectionIcon className="cardicon" />
              </div>
            </div>
          </Link>
        </div>

        <div className="Box2">
          <div className="cards">
            <div className="number">
              <CountUp end={songCount} duration={2} separator="," />
              <div className="cardNames">Scenes</div>
            </div>
            <div className="iconBx">
              <PortraitIcon className="cardicon" />
            </div>
          </div>

          <div className="cards">
            <div className="number">
              0<div className="cardNames">Users</div>
            </div>
            <div className="iconBx">
              <VerifiedUserIcon className="cardicon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
