import React, { useEffect, useState, useContext } from "react";
import "rsuite/dist/rsuite.min.css";
import { SelectPicker, RadioGroup, Radio } from "rsuite";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./MapLocation.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const MapLocation = () => {
  const SongsAndScenesDataContext = React.createContext();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  const movieId = queryParams.get("movieId");
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [songsAndScene, setSongsAndScene] = useState("");
  const [selectedLocationValue, setSelectedLocationValue] = useState("");
  const [selectedLocationImage, setSelectedLocationImage] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [type, setType] = useState("scene");
  const [url, setUrl] = useState("");
  const [songsAndSceneError, setSongsAndSceneError] = useState("");
  const [selectedLocationError, setSelectedLocationError] = useState("");
  const [submittedSelections, setSubmittedSelections] = useState([]);
  const [editingData, setEditingData] = useState(null);
  const [id, setId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedItemIdToDelete, setSelectedItemIdToDelete] = useState(null);
  const [newlyAddedId, setNewlyAddedId] = useState(null);

  useEffect(() => {
    loadLoactionData();
  }, [id]);

  const loadLoactionData = async () => {
    try {
      const result = await axios.get("https://wherewasitshot.com/api/location/getList");
      const locationNamesArray = result.data.map((element) => ({
        label: element.locationName,
        value: element.locationName,
      }));
      setSongs(locationNamesArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleChange = async (value) => {
    setSelectedLocationValue(value);
    setSelectedLocationImage(null);

    try {
      const result = await axios.get(
        `https://wherewasitshot.com/api/location/findByName/${encodeURIComponent(value)}`
      );
      if (result.data && result.data[0].image) {
        setSelectedLocationImage(result.data[0].image);
        setSelectedLocationId(result.data[0].id);
      }
    } catch (error) {
      console.error("Error fetching location details:", error);
    }
  };

  const validateInputs = () => {
    let isValid = true;
    if (!songsAndScene) {
      setSongsAndSceneError("*Field is required.");
      isValid = false;
    } else {
      setSongsAndSceneError("");
    }
    if (!selectedLocationValue) {
      setSelectedLocationError("*Please select a location.");
      isValid = false;
    } else {
      setSelectedLocationError("");
    }
    return isValid;
  };

  const handleEditSelection = (index) => {
    const selectedSelection = submittedSelections[index];
    setEditingData(selectedSelection);
    setSongsAndScene(selectedSelection.description);
    setSelectedLocationValue(selectedSelection.locationName);
    setType(selectedSelection.type);
    setUrl(selectedSelection.url);
    setSelectedLocationImage(selectedSelection.selectedLocationImage);
    setSelectedLocationId(selectedSelection.locationId);
    setId(selectedSelection.id);
    setIsEditMode(true);
  };

  const handleResetFields = () => {
    setSongsAndScene("");
    setSelectedLocationValue("");
    setType("scene");
    setUrl("");
    setId(null); // Reset the id when resetting the form
    setNewlyAddedId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInputs();
    const token = sessionStorage.getItem("token");

    if (!isValid || !token) {
        return;
    }

    const newData = {
        movieId: movieId,
        description: songsAndScene,
        locationName: selectedLocationValue,
        type: type,
        url: url,
        selectedLocationImage: selectedLocationImage,
        locationId: selectedLocationId,
        id: id,
    };

    try {
        if (!isEditMode) {
            const response = await axios.post(
                "https://wherewasitshot.com/api/songsandscene/add",
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const newId = response.data.id;
            if (newId) {
                setNewlyAddedId(newId);
            }
            newData.id = newId; // Add the newly generated id to the new entry
            setSubmittedSelections([...submittedSelections, newData]);
        }

        if (isEditMode && id) {
            // If in edit mode, update the existing entry
            await axios.put(
                `https://wherewasitshot.com/api/songsandscene/update/${id}`,
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // Find the index of the edited selection in the submittedSelections array
            const editedIndex = submittedSelections.findIndex(
                (selection) => selection.id === id
            );
            // Create a copy of the submittedSelections array and replace the edited selection with the updated data
            const updatedSelections = [...submittedSelections];
            updatedSelections[editedIndex] = newData;
            setSubmittedSelections(updatedSelections);
        }

        setIsEditMode(false);
        handleResetFields();
        setSelectedLocationImage(null);
        // navigate(`/movies/add?tab=map-location&movieId=${movieId}`);
    } catch (error) {
        console.error("Error:", error);
        // Check if the error message indicates that the token has expired
        if (error.response && error.response.status === 403) {
            navigate('/admin');
        }
    }
};

  const handleDeleteSelection = (id) => {
    setSelectedItemIdToDelete(id);
    setShowConfirmationModal(true);
  };
  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `https://wherewasitshot.com/api/songsandscene/delete/${selectedItemIdToDelete}`
      );
      const updatedSelections = submittedSelections.filter(
        (selection) => selection.id !== selectedItemIdToDelete
      );
      setSubmittedSelections(updatedSelections);
      setShowConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting selection:", error);
    }
  };
  const cancelDelete = () => {
    setSelectedItemIdToDelete(null);
    setShowConfirmationModal(false);
  };

  return (
    <div className="mapmovie">
      <form onSubmit={handleSubmit}>
        <div className="locationmap">
          <div className="form-section">
            <div className="radio mb-4 mt-4 mx-1  mx-2 radio">
              <RadioGroup
                name="type"
                value={type}
                onChange={(value) => setType(value)}
                inline
              >
                <Radio value="scene">Scene</Radio>
                <Radio value="song">Song</Radio>
              </RadioGroup>
            </div>
            <div className="mb-3">
              <label htmlFor="SongsAndScene" className="form-label">
                Description*
              </label>
              <textarea
                type="textarea"
                className="form-control songform"
                id="containerr"
                name="songsAndScene"
                value={songsAndScene}
                onChange={(e) => {
                  setSongsAndScene(e.target.value);
                  setSongsAndSceneError("");
                }}
              />
              <div className="error-message">{songsAndSceneError}</div>
            </div>

            <div className="mb-4">
              <label htmlFor="SelectLocation" className="form-label">
                Select Filmed Location*
              </label>
              <div className="select-picker-wrapper">
                {!loading && (
                  <SelectPicker
                    className="selectpicker"
                    placeholder="Please Select Filmed Location"
                    data={songs}
                    onChange={handleChange}
                    value={selectedLocationValue}
                  />
                )}
                {loading && <p>Loading...</p>}
              </div>
              <div className="error-message">{selectedLocationError}</div>
            </div>

            <div className="mb-3 ">
              <label htmlFor="URL" className="form-label">
                URL (optional)
              </label>
              <input
                type="text"
                className="form-control"
                id="url"
                name="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            {selectedLocationValue && (
              <div className="selected-location-container">
                <div className="location-imagess">
                  <img
                    src={`${selectedLocationImage}`}
                    alt="Selected Location"
                    style={{ maxWidth: "300px", height: "150px" }}
                  />
                </div>
              </div>
            )}
            <div className="d-flex">
              <button type="submit" className="btn custom-btn-primary">
                {isEditMode ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {submittedSelections.length > 0 && (
  <div className="submitted-selections">
    <div className="movie_seclected">
      {submittedSelections.map((selection, index) => (
        <div key={index} className="movie_card">
          <div className="selection">
            {selection.selectedLocationImage && (
              <div className="location-imagess">
                <img
                  className="filmImg"
                  src={`${selection.selectedLocationImage}`}
                  alt="Selected Location"
                  style={{ maxWidth: "500px", height: "100px" }} 
                />
              </div>
            )}

            <p className="decs" style={{ textAlign: "start", maxWidth: "200px" }}>
              {selection.locationName}
            </p>

            <div className="rowButton" id="btnIcon">
              <button
                className="edit-button btnIconD"
                onClick={() => handleEditSelection(index)}
              >
                <EditIcon />
              </button>
              <button
                className="delete-button btnIconD"
                onClick={() => handleDeleteSelection(selection.id)}
              >
                <DeleteIcon />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)}


      {showConfirmationModal && (
        <div className="confirmation-modal-overlay">
          <div className="confirmation-modal">
            <p className="message">Are you sure you want to delete ?</p>
            <div className="buttons">
              <button className="confirm-button" onClick={handleConfirmDelete}>
                Confirm
              </button>
              <button className="cancel-button" onClick={cancelDelete}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default MapLocation;
