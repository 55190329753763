import React, { useState, useEffect } from "react";
import "./MovieList.scss";
import SearchIcon from "@mui/icons-material/Search";
import Paginations from "../Paginations";
import Navbar from "../Home/Navbar/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";

const MovieList = () => {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("date-desc");
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const moviesPerPage = 12;
  const [filteredMoviesByLanguage, setFilteredMoviesByLanguage] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);

  // UseEffect to fetch movie data when the component mounts or when selectedFilter changes
  useEffect(() => {
    // Fetch movie data from your API endpoint
    fetch("https://wherewasitshot.com/api/movie/retrieveall")
      .then((response) => response.json())
      .then((data) => {
        let sortedMovies = [...data];

        if (selectedFilter === "date-desc") {
          // Sort by date in descending order
          sortedMovies.sort(
            (a, b) => new Date(b.lastUpdatedDate) - new Date(a.lastUpdatedDate)
          );
        } else if (selectedFilter === "title-asc") {
          // Sort by title in ascending order (A-Z), ignoring null or undefined movieName
          sortedMovies.sort((a, b) => {
            const titleA = (a.movieName || "").toLowerCase();
            const titleB = (b.movieName || "").toLowerCase();
            return titleA.localeCompare(titleB);
          });
        } else if (selectedFilter === "title-desc") {
          // Sort by title in descending order (Z-A), ignoring null or undefined movieName
          sortedMovies.sort((a, b) => {
            const titleA = (a.movieName || "").toLowerCase();
            const titleB = (b.movieName || "").toLowerCase();
            return titleB.localeCompare(titleA);
          });
        }

        // Filter out duplicate images based on movieId
        const uniqueMovies = sortedMovies.reduce((unique, movie) => {
          if (!unique.some((item) => item.movieId === movie.movieId)) {
            unique.push(movie);
          }
          return unique;
        }, []);

        setMovies(uniqueMovies);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [selectedFilter]);

  // UseEffect to fetch available languages when the component mounts
  useEffect(() => {
    // Fetch available languages from your API endpoint
    fetch("https://wherewasitshot.com/api/meta/movielanguage/get")
      .then((response) => response.json())
      .then((data) => {
        // Extract the language names from the response data
        const languageNames = data.map((language) => language.name);
        // Update the availableLanguages state
        setAvailableLanguages(["All", ...languageNames]);
      })
      .catch((error) => {
        console.error("Error fetching language data:", error);
      });
  }, []);

  // UseEffect to fetch movies by language when selectedLanguage changes
  useEffect(() => {
    if (selectedLanguage !== "All") {
      // Fetch movies by language
      fetch(
        `https://wherewasitshot.com/api/movie/retrieveByLanguage/${selectedLanguage}`
      )
        .then((response) => response.json())
        .then((data) => {
          setFilteredMoviesByLanguage(data);
        })
        .catch((error) => {
          console.error("Error fetching data by language:", error);
        });
    }
  }, [selectedLanguage]);

  // Filter movies based on selectedLanguage and search term

  const filteredMovies = movies
    .filter(
      (movie) =>
        selectedLanguage === "All" || movie.language === selectedLanguage
    )
    .filter((movie) =>
      movie.movieName?.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // Calculate the indexes for the movies to display on the current page
  const indexOfLastMovie = currentPage * moviesPerPage;
  const indexOfFirstMovie = indexOfLastMovie - moviesPerPage;
  // If selectedLanguage is "All", always display 12 images per page
  const currentMovies = filteredMovies.slice(
    indexOfFirstMovie,
    indexOfLastMovie
  );

  // Change page function
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate an array of page numbers for pagination
  const pageNumbers = [];
  const totalMovies =
    selectedLanguage === "All"
      ? filteredMovies.length
      : filteredMoviesByLanguage.length;
  for (let i = 1; i <= Math.ceil(totalMovies / moviesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
    <div className="movieList new-media-styles">
      <Navbar />
      <p className="text-center browseMovie">BROWSE MOVIES</p>
      <br />
      <div className="container tttt">
        <div className="custom_row">
          <div className="languages  col-lg-3">
            {availableLanguages.map((language) => (
              <p
                key={language}
                className={selectedLanguage === language ? "active" : ""}
                onClick={() => setSelectedLanguage(language)}
              >
                {language}
              </p>
            ))}
          </div>
          <div className="search_Sort col-lg-9">
            <div className="search-container col-lg-7 ">
              <input
                type="text"
                placeholder="Search movies..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchIcon className="searchicon" />
            </div>
            <div className="filter col-lg-5">
              <div className="filter-container">
                <label htmlFor="filter">
                </label>
                <select
                  id="filter"
                  name="filter"
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                >
                  <option value="date-desc"> Sort: Latest</option>
                  <option value="title-asc">Title (A-Z)</option>
                  <option value="title-desc">Title (Z-A)</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {loading ? (
            <p>Loading...</p>
          ) : currentMovies.length === 0 ? (
            <p className="alert alert-danger" role="alert">No movie found.</p>
          ) : (
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 listingMovie justify-content-center">
              {currentMovies.map((movie) => (
                <div key={movie.movieId} className="col mb-4 movies">
                  <div className="cardar ">
                    <Link
                      to={`/movie/${movie.movieId}`}
                      className="carard-link"
                    >
                      <img
                        src={movie.image}
                        alt={movie.movieName}
                        className="cardar-img-top"
                      />
                      <div className="cardar-body">
                        <h5 className="cardar-title">{movie.movieName.toUpperCase()}</h5>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Pagination */}
        <div className="row">
          <div className="col text-center">
            <Paginations
              currentPage={currentPage}
              totalPages={Math.ceil(filteredMovies.length / moviesPerPage)}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
     <Footer />
     </>
  );
 
};

export default MovieList;
