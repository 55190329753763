import React, { useState, useEffect } from "react";
import "./LocationList.scss";
import SearchIcon from "@mui/icons-material/Search";
import Paginations from "../../Paginations";
import Navbar from "../../Home/Navbar/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Footer";

const LocationList = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("date-desc");
  const [currentPage, setCurrentPage] = useState(1);
  const locationsPerPage = 12;
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [availableCountries, setAvailableCountries] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [availableCategories, setAvailableCategories] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);

  useEffect(() => {
    fetch("https://wherewasitshot.com/api/meta/locationcategory/get")
      .then((response) => response.json())
      .then((data) => {
        const categoryNames = data.map((category) => category.name);
        setSelectedCategory("All");
        setAvailableCategories(["All", ...categoryNames]);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  useEffect(() => {
    const filtered = locations.filter(location =>
      (location.locationName && location.locationName.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    
    setFilteredLocations(filtered);
  }, [searchTerm, locations]);

  useEffect(() => {
    fetch("https://wherewasitshot.com/api/location/getList")
      .then((response) => response.json())
      .then((data) => {
        let sortedLocations = [...data];

        if (selectedFilter === "date-desc") {
          sortedLocations.sort(
            (a, b) => new Date(b.lastUpdatedDate) - new Date(a.lastUpdatedDate)
          );
        } else if (selectedFilter === "title-asc") {
          sortedLocations.sort((a, b) => {
            const titleA = (a.locationName || "").toLowerCase();
            const titleB = (b.locationName || "").toLowerCase();
            return titleA.localeCompare(titleB);
          });
        } else if (selectedFilter === "title-desc") {
          sortedLocations.sort((a, b) => {
            const titleA = (a.locationName || "").toLowerCase();
            const titleB = (b.locationName || "").toLowerCase();
            return titleB.localeCompare(titleA);
          });
        }

        const uniqueLocations = sortedLocations.reduce((unique, location) => {
          if (!unique.some((item) => item.id === location.id)) {
            unique.push(location);
          }
          return unique;
        }, []);

        setLocations(uniqueLocations);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [selectedFilter]);

  useEffect(() => {
    fetch("https://wherewasitshot.com/api/location/getCountries")
      .then((response) => response.json())
      .then((data) => {
        const countryNames = data.map((country) => country.name);
        setSelectedCountry("All");
        setAvailableCountries(["All", ...countryNames]);
      })
      .catch((error) => {
        console.error("Error fetching country data:", error);
      });
  }, []);

  const handleSortChange = (value) => {
    setSelectedFilter(value);
  };

  const handleCountryChange = async (value) => {
    setSelectedCountry(value);
    try {
      let data;
      if (selectedCategory === "All") {
        if (value === "All") {
          const response = await fetch(
            "https://wherewasitshot.com/api/location/getList"
          );
          data = await response.json();
        } else {
          const response = await fetch(
            `https://wherewasitshot.com/api/location/retrieveByTypeAndCountry/${selectedCategory}/${value}`
          );
          data = await response.json();
        }
      } else {
        if (value === "All") {
          const response = await fetch(
            `https://wherewasitshot.com/api/location/retrieveByTypeAndCountry/${selectedCategory}/${value}`
          );
          data = await response.json();
        } else {
          const response = await fetch(
            `https://wherewasitshot.com/api/location/retrieveByTypeAndCountry/${selectedCategory}/${value}`
          );
          if (response.status === 404) {
            setLocations([]);
            return;
          }
          data = await response.json();
        }
      }

      if (data && data.length > 0) {
        const sortedData = sortLocations(data, selectedFilter);
        setLocations(sortedData);
      } else {
        setLocations([]);
      }
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  };

  const handleCategoryChange = async (value) => {
    setSelectedCategory(value);
    try {
      let data;
      if (value === "All") {
        const response = await fetch("https://wherewasitshot.com/api/location/getList");
        data = await response.json();
      } else {
        const response = await fetch(
          `https://wherewasitshot.com/api/location/retrieveByTypeAndCountry/${value}/${selectedCountry}`
        );
        if (response.status === 404) {
          setLocations([]);
          return;
        }
        data = await response.json();
      }

      if (data && data.length > 0) {
        const sortedData = sortLocations(data, selectedFilter);
        setLocations(sortedData);
      } else {
        setLocations([]);
      }
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  };

  const sortLocations = (locationsToSort, sortBy) => {
    let sortedLocations = [...locationsToSort];

    if (sortBy === "date-desc") {
      sortedLocations.sort(
        (a, b) => new Date(b.lastUpdatedDate) - new Date(a.lastUpdatedDate)
      );
    } else if (sortBy === "title-asc") {
      sortedLocations.sort((a, b) => {
        const titleA = (a.locationName || "").toLowerCase();
        const titleB = (b.locationName || "").toLowerCase();
        return titleA.localeCompare(titleB);
      });
    } else if (sortBy === "title-desc") {
      sortedLocations.sort((a, b) => {
        const titleA = (a.locationName || "").toLowerCase();
        const titleB = (b.locationName || "").toLowerCase();
        return titleB.localeCompare(titleA);
      });
    }

    return sortedLocations;
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredLocations.length / locationsPerPage); i++) {
    pageNumbers.push(i);
  }

  const indexOfLastLocation = currentPage * locationsPerPage;
  const indexOfFirstLocation = indexOfLastLocation - locationsPerPage;
  const currentLocations = filteredLocations.slice(
    indexOfFirstLocation,
    indexOfLastLocation
  );

  return (
    <>
      <div className="locationList">
        <Navbar />
        <p className="text-center browseLocation">BROWSE LOCATIONS</p>
        <br />
        <div className="container">
          <div className="filterlocation">
            <div className="locationDropdown col-md-3">
              <div className="locationPrefrence-container">
                <label htmlFor="locationDropdown"></label>
                <select
                  id="locationDropdown"
                  value={selectedCountry}
                  onChange={(e) => handleCountryChange(e.target.value)}
                >
                  <option value="All">Continent: All</option>
                  <option value="India">India</option>
                  <option value="Asia">Asia</option>
                  <option value="Americas">Americas</option>
                  <option value="Australia">Australia</option>
                  <option value="Africa">Africa</option>
                  <option value="Europe">Europe</option>
                </select>
              </div>
            </div>

            <div className="search-container col-md-5">
              <input
                type="text"
                placeholder="Search locations..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchIcon className="searchicon" />
            </div>

            <div className="categoryFilter col-md-2 ms-2">
              <div className="categoryFilter-container">
                <label htmlFor="categoryFilter"></label>
                <select
                  id="categoryFilter"
                  name="categoryFilter"
                  value={selectedCategory}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                >
                  <option value="All">Category: All</option>
                  {availableCategories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="filter col-md-2">
              <div className="filter-containerr sortby">
                <label htmlFor="filter"></label>
                <select
                  id="filter"
                  name="filter"
                  value={selectedFilter}
                  onChange={(e) => handleSortChange(e.target.value)}
                >
                  <option value="date-desc">Sort: Latest</option>
                  <option value="title-asc">Title (A-Z)</option>
                  <option value="title-desc">Title (Z-A)</option>
                </select>
              </div>
            </div>
          </div>

          <div className="container">
          {loading ? (
            <p>Loading...</p>
          ) : currentLocations.length === 0 ? (
            <p className="alert alert-danger" role="alert">
              No locations found for the selected type and country.
            </p>
          ) : (
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 listingLocation justify-content-center">
              {currentLocations.map((location) => (
                <div
                  key={location.locationName}
                  className="col mb-4 locations"
                >
                  <div className="cardar">
                    <Link
                      to={`/location/${location.id}`}
                      className="cardar-link"
                    >
                      <img
                        src={location.image}
                        alt={location.locationName}
                        className="cardar-img-top"
                      />
                      <div className="cardar-body">
                        <h5
                          className="cardar-title"
                        >
                          {location.locationName.toUpperCase()}
                        </h5>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>

          <div className="row">
            <div className="col text-center">
              <Paginations
                currentPage={currentPage}
                totalPages={Math.ceil(filteredLocations.length / locationsPerPage)}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LocationList;
