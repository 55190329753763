import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Locationcarousel.scss";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

function LocationCarousel() {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    axios
      .get("https://wherewasitshot.com/api/location/top10Location")
      .then((response) => {
        const apiImages = response.data.map((item) => item);
        setImages(apiImages.concat(apiImages));
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!transitioning) {
        nextSlide();
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [images, transitioning]);

  const prevSlide = () => {
    if (!transitioning) {
      setTransitioning(true);
      setCurrentIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + images.length) % images.length;
        setTimeout(() => setTransitioning(false), 500);
        return newIndex;
      });
    }
  };

  const nextSlide = () => {
    if (!transitioning) {
      setTransitioning(true);
      setCurrentIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setTimeout(() => setTransitioning(false), 500);
        return newIndex;
      });
    }
  };

  return (
    <div className="location-carousel">
      <h3 className="top10loc">
        {/* Latest Location */}
        LATEST LOCATIONS
        </h3>
      <button onClick={prevSlide} className="prev-button1">
        {/* <NavigateBeforeIcon style={{ fontSize: 60 }} /> */}
      </button>
      <div className="slider">
        <div
          className="slider-inner"
          style={{
            width: `${images.length * 340}px`,
            transform: `translateX(-${currentIndex * 320}px)`,
            transition: transitioning ? "transform 0.5s ease" : "none",
          }}
        >
          {images.map((imageData) => (
            <div key={imageData.id} className="card">
              <Link to={`/location/${imageData.id}`}>
                {" "}
                {/* Link to LocationDetail with the location ID */}
                <img
                  src={imageData.image}
                  alt={`Slide ${imageData.locationName}`}
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
      <button onClick={nextSlide} className="next-button1">
        {/* <NavigateNextIcon style={{ fontSize: 60 }} /> */}
      </button>
    </div>
  );
}

export default LocationCarousel;
