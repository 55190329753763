import React, { useEffect, useState } from "react";
import "./Paginations.scss";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";

const Paginations = ({ currentPage, totalPages, onPageChange }) => {
  const [pagesToShow, setPagesToShow] = useState(10);

  // Function to update the number of pages to show based on screen width
  const updatePagesToShow = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 576) {
      setPagesToShow(5); // Small screens (e.g., phones) show 5 pages
    } else if (screenWidth < 992) {
      setPagesToShow(7); // Medium screens (e.g., tablets) show 7 pages
    } else {
      setPagesToShow(10); // Larger screens show 10 pages
    }
  };

  // Update pagesToShow when the component mounts and when the window resizes
  useEffect(() => {
    updatePagesToShow();
    window.addEventListener("resize", updatePagesToShow);
    return () => {
      window.removeEventListener("resize", updatePagesToShow);
    };
  }, []);

  // Calculate the range of pages to display
  const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

  // Generate an array of page numbers to display
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {currentPage > 1 && (
          <li className="page-item">
            <a
              href="#"
              className="page-link"
              onClick={() => onPageChange(currentPage - 1)}
            >
              <SkipPreviousIcon />
            </a>
          </li>
        )}
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => onPageChange(number)}
            >
              {number}
            </a>
          </li>
        ))}
        {currentPage < totalPages && (
          <li className="page-item">
            <a
              href="#"
              className="page-link"
              onClick={() => onPageChange(currentPage + 1)}
            >
              <SkipNextIcon />
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Paginations;
