import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./MovieDetail.scss";
import Navbar from "../Home/Navbar/Navbar";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import Footer from "../Home/Footer";

const MovieDetail = () => {
  const { movieId } = useParams(); // Access movieId directly from props
  // console.log("Movie ID:", movieId); // Log to verify movieId is correct

  const [movie, setMovie] = useState({});
  const [songsAndScenes, setSongsAndScenes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch movie data by movieId from your API endpoint
    fetch(`https://wherewasitshot.com/api/movie/retrieve/${movieId}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Movie Data:", data); // Log movie data to see if it's coming correctly
        setMovie(data);
        setLoading(false);
        // Update page title dynamically
        document.title = `${data.movieName} - Where Was It Shot`;
      })
      .catch((error) => {
        console.error("Error fetching movie details:", error);
        setLoading(false);
      });

    // Fetch songs and scenes data by movieId from the API endpoint
    fetch(`https://wherewasitshot.com/api/songsandscene/bymovie/${movieId}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Songs and Scenes Data:", data); // Log data to see if it's coming correctly
        setSongsAndScenes(data);
      })
      .catch((error) => {
        console.error("Error fetching songs and scenes:", error);
      });
  }, [movieId]);

  return (
    <>
      <Navbar />
      <div className="movie-detail-container">
        <Link to="/moviesList">
          <ReplyAllIcon className="readmore" />
          Browse Movies
        </Link>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="movie-details row">
            <div className="col-md-6 ">
              <img
                src={movie.image}
                alt={movie.movieName}
                className="img-fluid mt-1 movie-image"
                // style={{ width: "450px", height: "250px" }}
              />
            </div>
            <div className="col-md-6 movieDetailss">
              <p className="movname">{movie.movieName.toUpperCase()}</p>
              <p className="detail">RELEASED IN : {movie.releasedYear}</p>
              <p className="detail">STARRING : {movie.starring.toUpperCase()}</p>
              <p className="detail">MUSIC : {movie.music.toUpperCase()}</p>
              <p className="detail">DIRECTION : {movie.direction.toUpperCase()}</p>
              <p className="detail">{movie.description}</p>
              <p className="detail">
                <a
                  href={movie.wikiLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  READ MORE <MenuBookIcon className="readmore" />
                </a>
              </p>
            </div>
          </div>
        )}
        <div className="designnnnn"> </div>
        {/* Display songs and scenes data */}
        <div className="songs-and-scenes">
          <h2 className="filminglocation">FILMING LOCATIONS</h2>
          {songsAndScenes.length > 0 ? (
            <ul>
              {songsAndScenes.map((item, index) => (
                <li key={index}>
                  <Link to={`/location/${item.locationId}`}>
                    <div className="location-wrapper">
                      <p className="location-description">{item.description.toUpperCase()}</p>
                      <p className="location-namee">{item.locationName}</p>
                      {/* Pass locationId as a prop to LocationInfo */}
                      {item.locationId && (
                        <LocationInfo locationId={item.locationId} />
                      )}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          ) 
          : (
            <p className="alert " role="alert">
              {/* No movie has been shot in this location */}
            </p>
          )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

const LocationInfo = ({ locationId }) => {
  const [locationData, setLocationData] = useState({});

  useEffect(() => {
    // Fetch location data by locationId from your API endpoint
    fetch(`https://wherewasitshot.com/api/location/get/${locationId}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Location Data:", data);
        setLocationData(data);
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  }, [locationId]);

  return (
    <>
    <div className="filLoc">
      <div className="location-info" >
        {/* Wrap the image in a Link component */}
        {locationData.image && (
          <Link to={`/location/${locationId}`}>
            <img
              src={locationData.image}
              alt={`Location Image ${locationId}`}
              className="img-fluid locationImage"
              // style={{ width: "320px", height: "180px" }}
            />
          </Link>
        )}
      </div>
      <div className="locName">
        {locationData.locationName && (
          <p className="xyz" >
            {" "}
            {locationData.locationName.toUpperCase()}
          </p>
        )}
      </div>
      </div>
    </>
  );
};

export default MovieDetail;