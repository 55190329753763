import React, { useState, useEffect } from "react";
import "rsuite/dist/rsuite.min.css";
import { SelectPicker, RadioGroup, Radio } from "rsuite";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./MapLocation.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const MoviesLocationEdit = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  const { moviesId } = useParams();
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [songsAndScene, setSongsAndScene] = useState("");
  const [selectedLocationValue, setSelectedLocationValue] = useState("");
  const [selectedLocationImage, setSelectedLocationImage] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [type, setType] = useState("scene");
  const [url, setUrl] = useState("");
  const [songsAndSceneError, setSongsAndSceneError] = useState("");
  const [selectedLocationError, setSelectedLocationError] = useState("");
  const [submittedSelections, setSubmittedSelections] = useState([]);
  const [editingData, setEditingData] = useState(null);
  const [id, setId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedItemIdToDelete, setSelectedItemIdToDelete] = useState(null);
  const [newlyAddedId, setNewlyAddedId] = useState(null);

  useEffect(() => {
    loadMovies();
    if (moviesId) {
      loadSongs();
    }
  }, [id]);

  const [songs, setSongsAndScenes] = useState([]);
  const {
    description,
    songsurl,
    locationId,
    songstype,
    songsimg,
    locationName,
    songsid,
  } = songs;

  const loadSongs = async () => {
    const songsAndScenesResponse = await axios.get(
      `https://wherewasitshot.com/api/songsandscene/bymovie/${moviesId}`
    );

    const songsAndScenesData = songsAndScenesResponse.data;
    const updatedSongs = [];
    for (const data of songsAndScenesData) {
      const locationId = data["locationId"]; // Extract locationId
      // console.log('Fetching data for locationId:', locationId);
      
      const locationImgResponse = await axios.get(
        `https://wherewasitshot.com/api/location/get/${data["locationId"]}`
      );

      const locationImg = locationImgResponse.data.image;
      updatedSongs.push({
        description: data["description"],
        songsurl: data["url"],
        locationId: data["locationId"],
        songstype: data["type"],
        songsimg: locationImg,
        locationName: locationImgResponse.data.locationName,
        songsid: data.id,
      });
    }
    setSongsAndScenes(updatedSongs);
  };

  const loadMovies = async () => {
    try {
      const result = await axios.get("https://wherewasitshot.com/api/location/getList");
      const locationNamesArray = result.data.map((element) => ({
        label: element.locationName,
        value: element.locationName,
      }));
      setMovies(locationNamesArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleChange = async (value) => {
    setSelectedLocationValue(value);
    setSelectedLocationImage(null);

    try {
      const result = await axios.get(
        `https://wherewasitshot.com/api/location/findByName/${encodeURIComponent(value)}`
      );
      if (result.data && result.data[0].image) {
        setSelectedLocationImage(result.data[0].image);
        setSelectedLocationId(result.data[0].id);
      }
    } catch (error) {
      console.error("Error fetching location details:", error);
    }
  };

  const validateInputs = () => {
    let isValid = true;
    if (!songsAndScene) {
      setSongsAndSceneError("*Field is required.");
      isValid = false;
    } else {
      setSongsAndSceneError("");
    }

    if (!selectedLocationValue) {
      setSelectedLocationError("*Please select a location.");
      isValid = false;
    } else {
      setSelectedLocationError("");
    }

    return isValid;
  };

  const handleResetFields = () => {
    setSongsAndScene("");
    setSelectedLocationValue("");
    setType("scene");
    setUrl("");
    setId(null); // Reset the id when resetting the form
    setNewlyAddedId(null);
  };

  const handleSecondEditSelection = async (index) => {
    const selectedSelection = songs[index];
    // Set the state variables to the values of the selected item
    setEditingData(selectedSelection);
    setSongsAndScene(selectedSelection.description);
    setSelectedLocationValue(selectedSelection.locationName);
    setType(selectedSelection.songstype);
    setUrl(selectedSelection.songsurl);
    setSelectedLocationImage(selectedSelection.songsimg);
    setSelectedLocationId(selectedSelection.locationId);
    setId(selectedSelection.songsid);
    setIsEditMode(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInputs();
    const token = sessionStorage.getItem("token");

    if (!isValid || !token) {
        return;
    }

    const newData = {
        movieId: moviesId,
        description: songsAndScene,
        locationName: selectedLocationValue,
        type: type,
        url: url,
        locationId: selectedLocationId,
        id: id,
    };

    try {
        if (!isEditMode) {
            const response = await axios.post(
                "https://wherewasitshot.com/api/songsandscene/add",
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const newId = response.data.id;
            if (newId) {
                // Fetch the image for the new location immediately after adding data
                const locationImgResponse = await axios.get(
                    `https://wherewasitshot.com/api/location/get/${selectedLocationId}`
                );
                const locationImg = locationImgResponse.data.image;
                newData.selectedLocationImage = locationImg;

                // Update the songs state with the new data including the image
                setSongsAndScenes((prevSongs) => [...prevSongs, newData]);

                // Clear the form fields after successful submission
                handleResetFields();

                // Call loadSongs to update the songs and scenes with the latest data
                loadSongs();
            }
        }

        if (isEditMode && id) {
            // If in edit mode, update the existing entry
            await axios.put(
                `https://wherewasitshot.com/api/songsandscene/update/${id}`,
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const editedIndex = songs.findIndex((song) => song.id === id);
            if (editedIndex !== -1) {
                const updatedSongs = [...songs];
                updatedSongs[editedIndex] = newData;
                setSongsAndScenes(updatedSongs);
            }

            // Call loadSongs to update the songs and scenes with the latest data
            loadSongs();
            handleResetFields();
        }

        setIsEditMode(false);
        // navigate(`/movies/updatemovie/${moviesId}`);
    } catch (error) {
        console.error("Error:", error);
        // Check if the error message indicates that the token has expired
        if (error.response && error.response.status === 403) {
            alert("Token expired. Redirecting to /admin");
            navigate('/admin');
        }
    }
};


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const isValid = validateInputs();
  //   const token = sessionStorage.getItem("token");

  //   if (!isValid || !token) {
  //    return;
  //  }

  //   const newData = {
  //     movieId: moviesId,
  //     description: songsAndScene,
  //     locationName: selectedLocationValue,
  //     type: type,
  //     url: url,
  //     locationId: selectedLocationId,
  //     id: id,
  //   };

  //   try {
  //     if (!isEditMode) {
  //       const response = await axios.post(
  //         "https://wherewasitshot.com/api/songsandscene/add",
  //         newData ,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       const newId = response.data.id;
  //       if (newId) {
  //         // Fetch the image for the new location immediately after adding data
  //         const locationImgResponse = await axios.get(
  //           `https://wherewasitshot.com/api/location/get/${selectedLocationId}`
  //         );
  //         const locationImg = locationImgResponse.data.image;
  //         newData.selectedLocationImage = locationImg;

  //         // Update the songs state with the new data including the image
  //         setSongsAndScenes((prevSongs) => [...prevSongs, newData]);

  //         // Clear the form fields after successful submission
  //         handleResetFields();

  //         // Call loadSongs to update the songs and scenes with the latest data
  //         loadSongs();
  //       }
  //     }

  //     if (isEditMode && id) {
  //       // If in edit mode, update the existing entry
  //       await axios.put(
  //         `https://wherewasitshot.com/api/songsandscene/update/${id}`,
  //         newData ,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       const editedIndex = songs.findIndex((song) => song.id === id);
  //       if (editedIndex !== -1) {
  //         const updatedSongs = [...songs];
  //         updatedSongs[editedIndex] = newData;
  //         setSongsAndScenes(updatedSongs);
  //       }

  //       // Call loadSongs to update the songs and scenes with the latest data
  //       loadSongs();
  //       handleResetFields();
  //     }

  //     setIsEditMode(false);
  //     // navigate(`/movies/updatemovie/${moviesId}`);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const handleDeleteSelection = (id) => {
    setSelectedItemIdToDelete(id);
    setShowConfirmationModal(true);
  };

  // const handleConfirmDelete = async () => {
  //   try {
  //     const token = sessionStorage.getItem("token");

  //     await axios.delete(
  //       `https://wherewasitshot.com/api/songsandscene/delete/${selectedItemIdToDelete}`,{
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     // Remove the deleted item from the songs array
  //     const updatedSongs = songs.filter(
  //       (song) => song.songsid !== selectedItemIdToDelete
  //     );
  //     setSongsAndScenes(updatedSongs);

  //     setShowConfirmationModal(false);
  //   } catch (error) {
  //     console.error("Error deleting selection:", error);
  //   }
  // };

  const handleConfirmDelete = async () => {
    try {
        const token = sessionStorage.getItem("token");

        await axios.delete(
            `https://wherewasitshot.com/api/songsandscene/delete/${selectedItemIdToDelete}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        // Remove the deleted item from the songs array
        const updatedSongs = songs.filter(
            (song) => song.songsid !== selectedItemIdToDelete
        );
        setSongsAndScenes(updatedSongs);

        setShowConfirmationModal(false);
    } catch (error) {
        console.error("Error deleting selection:", error);
        // Check if the error message indicates that the token has expired
        if (error.response && error.response.status === 403) {
            alert("Token expired. Redirecting to /admin");
            navigate('/admin');
        }
    }
};


  const cancelDelete = () => {
    setSelectedItemIdToDelete(null);
    setShowConfirmationModal(false);
  };

  return (
    <div className="mapmovie">
      <form onSubmit={handleSubmit}>
        <div className="locationmap">
          <div className="form-section">
            <div className="row">
              <div className="radio mb-4 mt-4 mx-1  mx-2 radio">
                <RadioGroup
                  name="type"
                  value={type}
                  onChange={(value) => setType(value)}
                  inline
                >
                  <Radio value="scene">Scene</Radio>
                  <Radio value="song">Song</Radio>
                </RadioGroup>
              </div>
              <div className="mb-3">
                <label htmlFor="SongsAndScene" className="form-label">
                  Description
                </label>
                <textarea
                  type="textarea"
                  className="form-control songform"
                  id="containerr"
                  name="songsAndScene"
                  value={songsAndScene}
                  onChange={(e) => {
                    setSongsAndScene(e.target.value);
                    setSongsAndSceneError("");
                  }}
                />
                <div className="error-message">{songsAndSceneError}</div>
              </div>
            </div>

            <div className="mb-4 ">
              <label htmlFor="SelectLocation" className="form-label">
                Select Filmed Location
              </label>
              <div className="select-picker-wrapper ">
                {!loading && (
                  <SelectPicker
                    className="selectpicker"
                    placeholder=" * Please Select Filming Locations"
                    data={movies}
                    onChange={handleChange}
                    value={selectedLocationValue}
                  />
                )}
                {loading && <p>Loading...</p>}
              </div>
              <div className="error-message">{selectedLocationError}</div>
            </div>

            <div className="mb-3 ">
              <label htmlFor="URL" className="form-label">
                URL (optional)
              </label>
              <input
                type="text"
                className="form-control"
                id="url"
                name="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>

            {selectedLocationValue && (
              <div className="selected-location-container">
                <div className="location-imagess">
                  <img
                    src={`${selectedLocationImage}`}
                    alt="Selected Location"
                    style={{ maxWidth: "300px", height: "150px" }}
                  />
                </div>
              </div>
            )}

            <div className="d-flex">
              <button type="submit" className="btn custom-btn-primary">
                {isEditMode ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>

      {moviesId && (
        <div className="submitted-selections">
          <div className="movie_seclected">
            {songs.map((data, index) => (
              <div key={index} className="movie_card">
                <div className="selection">
                  {data.songsimg && (
                    <div className="location-imagess">
                      <img
                        className="filmImggg"
                        src={`${data.songsimg}`}
                        alt="Selected Location"
                        style={{ maxWidth: "300px", height: "150px" }} 
                      />
                    </div>
                  )}

                  <p className="decs" style={{ textAlign: "start", maxWidth: "200px" }}>
                    {/* <strong>Location Name:</strong> */}
                     {data.locationName}
                  </p>

                  <div className="rowButton" id="btnIcon">
                    <button
                      className="edit-button btnIconD"
                      onClick={() => handleSecondEditSelection(index)}
                    >
                      <EditIcon sx={{ fontSize: "25px" }} />
                    </button>
                    <button
                      className="delete-button btnIconD"
                      onClick={() => handleDeleteSelection(data.songsid)}
                    >
                      <DeleteIcon sx={{ fontSize: "25px" }} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="confirmation-modal-overlay">
          <div className="confirmation-modal">
            <p className="message">Are you sure you want to delete ?</p>
            <div className="buttons">
              <button className="confirm-button" onClick={handleConfirmDelete}>
                Confirm
              </button>
              <button className="cancel-button" onClick={cancelDelete}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MoviesLocationEdit;
