import React from "react";
import { Routes, Route } from "react-router-dom";
import SignIn from "./pages/Login/SignIn";


const AdminRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
    </Routes>
  );
};

export default AdminRoute;
