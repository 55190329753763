// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyDKFf-SUm-9WtMiKg6tW8jHfAt3KkexCwY",
//   authDomain: "wherewasitshot-44719.firebaseapp.com",
//   projectId: "wherewasitshot-44719",
//   storageBucket: "wherewasitshot-44719.appspot.com",
//   messagingSenderId: "944452130779",
//   appId: "1:944452130779:web:3a44e9e68b48ea1876f9fe",
//   measurementId: "G-WR2WYK6E62",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// export const storage = getStorage(app);



import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBUKc9vqcGRn_C-azun-VAEbOXFMlhZktQ",
  authDomain: "wherewasitshot-6d46b.firebaseapp.com",
  projectId: "wherewasitshot-6d46b",
  storageBucket: "wherewasitshot-6d46b.appspot.com",
  messagingSenderId: "303445588189",
  appId: "1:303445588189:web:c0a3ba5de5f93075598334"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);