import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../Locations/LocationDetail.scss";

const YouMayAlsoLikeButton = () => {
  const { id: currentLocationId } = useParams();
  const [youMayAlsoLikeImages, setYouMayAlsoLikeImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data for the current location
        const currentLocationResponse = await fetch(
          `https://wherewasitshot.com/api/location/get/${currentLocationId}`
        );
        if (!currentLocationResponse.ok) {
          throw new Error(
            "Failed to fetch location data. Please check your internet connection."
          );
        }

        // Fetch data for similar locations based on tags
        const currentLocationData = await currentLocationResponse.json();
        const tagNames = currentLocationData.tags.map((tag) => tag.name);

        const promises = tagNames.map((tagName) =>
          fetch(`https://wherewasitshot.com/api/location/byTagName/${tagName}`)
            .then((response) => response.json())
            .catch((error) => {
              console.error("Error fetching images for tag:", error);
              return [];
            })
        );

        const results = await Promise.all(promises);
        let matchingImages = results.flat();

        // Remove the current location from similar locations
        matchingImages = matchingImages.filter(
          (location) => location.id !== currentLocationData.id
        );

        // Remove duplicates
        matchingImages = matchingImages.filter(
          (location, index, self) =>
            index === self.findIndex((l) => l.id === location.id)
        );

        // Sort locations by the number of matching tags in descending order
        matchingImages.sort((a, b) => b.tags.length - a.tags.length);

        // Limit the displayed images to a maximum of 6
        const limitedLocations = matchingImages.slice(0, 6);

        setYouMayAlsoLikeImages(limitedLocations);
        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [currentLocationId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (youMayAlsoLikeImages.length === 0) {
    return (
      <p className="text-danger text-center mb-4">
        No matched Location available for this location.
      </p>
    );
  }

  return (
    <div className="container youmayalsolike">
      <div className="row mb-4">
        {youMayAlsoLikeImages.map((item) => (
          <div key={item.id} className="col-md-4">
            <div className="cardar">
              <Link to={`/location/${item.id}`}>
                <img
                  src={item.image}
                  alt={item.locationName}
                  className="card-img-top youmayalsolikeImage"
                  style={{ height: "200px" }}
                />
              </Link>
              <div className="card-body">
                <h5 className="card-title text-center" style={{ fontSize: "14px" ,   color: 'rgb(0, 128, 255)' , fontWeight:'400'}}>{item.locationName.toUpperCase()}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YouMayAlsoLikeButton;
