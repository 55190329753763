import React, { useEffect } from "react";
import India from "../../Image/INdia.jpg";
import americas from "../../Image/AMerica.png";
import asia from "../../Image/ASia.png";
import europe from "../../Image/EUrope .jpg";
import australia from "../../Image/AUstralia1 .jpg";
import africa from "../../Image/AFrica .jpg";
import "./Carousel.scss";
import { Link, useParams } from "react-router-dom";

const ExploreLocation = () => {
  // const { location } = useParams();

  // useEffect(() => {
  //   document.title = `Locations in ${getLocationTitle(location)} - Where Was It Shot`;
  // }, [location]);

  // const getLocationTitle = (location) => {
  //   switch (location) {
  //     case "india":
  //       return "India";
  //     case "americas":
  //       return "Americas";
  //     case "asia":
  //       return "Asia";
  //     case "europe":
  //       return "Europe";
  //     case "australia":
  //       return "Australia";
  //     case "africa":
  //       return "Africa";
  //     default:
  //       return "";
  //   }
  // };

  return (
    <div className="container">
      <div className="explore-location">
        <p className="exploreloc">
          EXPLORE OUR LOCATIONS
        </p>

        <div className="row">
          <div className="col-md-4 mt-3">
            <Link to="/explore-location-detail/india">
              <div className="location">
                <div className="image-wrapper">
                  <img src={India} alt="India" className="img-fluid" />
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 mt-3">
            <Link to="/explore-location-detail/americas">
              <div className="location">
                <div className="image-wrapper">
                  <img src={americas} alt="Americas" className="img-fluid" />
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 mt-3">
            <Link to="/explore-location-detail/asia">
              <div className="location">
                <div className="image-wrapper">
                  <img src={asia} alt="Asia" className="img-fluid" />
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 mt-3">
            <Link to="/explore-location-detail/europe">
              <div className="location">
                <div className="image-wrapper">
                  <img src={europe} alt="Europe" className="img-fluid" />
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 mt-3">
            <Link to="/explore-location-detail/australia">
              <div className="location">
                <div className="image-wrapper">
                  <img src={australia} alt="Australia" className="img-fluid" />
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 mt-3">
            <Link to="/explore-location-detail/africa">
              <div className="location">
                <div className="image-wrapper">
                  <img src={africa} alt="Africa" className="img-fluid" />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreLocation;
