import React from "react";
import "./ContactUs.scss";
import Navbar from "./Home/Navbar/Navbar";
import Footer from "./Home/Footer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <div className="contactpage">
        <div className="contactUs">
          <p className="contact_head">CONTACT US</p>
          <div className="container">
            <div className="contactrow">
              <div className="contactCard col-md-6">
                <div class=" adress">
                  <div class="col-sm-6 mb-3 mb-sm-0 address">
                    <div class="addresscard">
                      <div class=" addressicon">
                        <LocationOnIcon className="addressIcon" />
                        <p class="card-text">Address</p>
                        <p class="address-text">
                          A108 Adam Street New York, NY 535022
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-3 mb-sm-0 call">
                    <div class="callcard">
                      <div class="callicon">
                        <CallIcon className="callIcon" />
                        <p class="card-text mt-1">Contact</p>
                        <p class="address-text mt-2">+14258942255</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Emailrow">
                  <div class="col-sm-6 mb-3 mb-sm-0">
                    <div class=" Emailcard">
                      <div class="email">
                        <EmailIcon className="emailIcon" />
                        <p class="card-text">Email Us</p>
                        <p class="email-text">wherewasitshot@gmail.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formmmmmmm justify-content-center">
                <div className="contactForm col-md-6">
                  <form className="contact_Forms">
                    <div class="row">
                      <div class="col-md-6 mt-2">
                        <label for="name" class="form-label"></label>
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div class="col-md-6 mt-2">
                        <label for="email" class="form-label"></label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div class="mb-2">
                      <label for="subject" class="form-label"></label>
                      <input
                        type="text"
                        class="form-control"
                        id="subject"
                        placeholder="Subject"
                        required
                      />
                    </div>
                    <div class="mb-2">
                      <label for="message" class="form-label"></label>
                      <textarea
                        class="form-control"
                        id="message"
                        rows="4"
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>
                    <div className="btnn">
                      <button type="submit" class="btn btn-primary mt-3 submitContactForm">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
