import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./LocationDetail.scss";
import Navbar from "../../Home/Navbar/Navbar";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import MovieShotedHereButton from "./MovieShotedHereButton";
import SongsAndScenesButton from "./SongsAndScenesButton";
import YouMayAlsoLikeButton from "./YouMayAlsoLikeButton";
import Footer from "../Footer";

const LocationDetail = () => {
  const { id } = useParams();
  const [locationDetail, setLocationDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeButton, setActiveButton] = useState("Movie Shoted Here");
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    // Fetch location detail by locationId from your API endpoint
    fetch(`https://wherewasitshot.com/api/location/get/${id}`)
      .then((response) => response.json())
      .then((data) => {
        const tagNames = data.tags.map((tag) => tag.name);
        setLocationDetail(data);
        setLoading(false);
        // console.log("Tag Names:", tagNames);
        // Update page title dynamically
        document.title = `${data.locationName} - Where Was It Shot`;
      })
      .catch((error) => {
        console.error("Error fetching location detail:", error);
        setLoading(false);
      });
  }, [id]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <>
      <Navbar />
      <div className="location-detail-container">
        <Link to="/locationsList">
          <ReplyAllIcon className="readmorel" /> Browse Locations
        </Link>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="location-details row">
            <div className="col-md-6">
              <img
                src={locationDetail.image}
                alt={locationDetail.locationName}
                className="img-fluid location-images mt-1"
                // style={{ width: "450px", height: "250px" }}
              />
            </div>
            <div className="col-md-6">
              <p className="location-name locname">
              {/* {locationDetail.locationName} - {locationDetail.city} */}
              {locationDetail.locationName.toUpperCase()}
              </p>
              <p className="location-description locdetail">
                {locationDetail.description}
              </p>
              {/* <p> <a className="wiki" href="#">READ MORE <MenuBookIcon/> </a></p> */}
               <p className="locdetail wiki">
                <a className="wiki"
                   href={locationDetail.wikiLink}
                   target="_blank"
                   rel="noopener noreferrer"
                 >
                   READ MORE <MenuBookIcon className="readmorewiki" />
                 </a>
               </p> 
            </div>
          </div>
        )}
      </div>

      <div className="col-md-12 mt-3 buttonlike">
        <div className="d-flex justify-content-center">
          <div className="btn-group col-11">
            <button
              className={`text-uppercase col-lg-4 col-md-6 col-sm-12 btn shothere ${
                activeButton === "Movie Shoted Here" ? "btn-danger" : "btn-dark"
              } p-3 `}
              onClick={() => handleButtonClick("Movie Shoted Here")}
            >
              Movies Shot Here
            </button>
            <button
              className={`text-uppercase col-lg-4 col-md-6 col-sm-12 btn shothere ${
                activeButton === "Songs & Scenes" ? "btn-danger" : "btn-dark"
              }`}
              onClick={() => handleButtonClick("Songs & Scenes")}
            >
              Songs & Scenes
            </button>
            <button
              className={`text-uppercase col-lg-4 col-md-6 col-sm-12 btn shothere ${
                activeButton === "You may also like" ? "btn-danger" : "btn-dark"
              }`}
              onClick={() => handleButtonClick("You may also like")}
            >
              You may also like
            </button>
          </div>
        </div>
      </div>

      {activeButton === "Movie Shoted Here" && (
        <MovieShotedHereButton id={id} />
      )}
      {activeButton === "Songs & Scenes" && <SongsAndScenesButton />}
      {activeButton === "You may also like" && (
        <YouMayAlsoLikeButton
          tagName={
            locationDetail.tags.length > 0 ? locationDetail.tags[0].name : ""
          }
        />
      )}

      {/* Display a message when there are no tags */}
      {activeButton === "You may also like" &&
        locationDetail.tags.length === 0 && (
          <div className="col-md-12 mt-3">
            {/* <p>No tags available for this location.</p> */}
          </div>
        )}
      <Footer />
    </>
  );
};

export default LocationDetail;
