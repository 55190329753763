import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./MovieForm.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { v4 } from "uuid";

const MovieForm = () => {
  const { moviesId } = useParams();
  const navigate = useNavigate();
  const [imageUpload, setImageUpload] = useState(null);
  const [movie, setMovie] = useState({
    movieName: "",
    releasedYear: "",
    starring: "",
    music: "",
    direction: "",
    description: "",
    image: "",
    wikiLink: "",
    language: "",
  });

  const {
    movieName,
    description,
    releasedYear,
    starring,
    music,
    direction,
    wikiLink,
    language,
    image,
  } = movie;

  const [movieNameError, setMovieNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [releasedYearError, setReleasedYearError] = useState("");
  const [starringError, setStarringError] = useState("");
  const [musicError, setMusicError] = useState("");
  const [directionError, setDirectionError] = useState("");
  const [wikiLinkError, setWikiLinkError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [imageDimensionsError, setImageDimensionsError] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [uploadedImagePath, setUploadedImagePath] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [categories, setCategories] = useState([]);
  const [imageError, setImageError] = useState("");
  const [imageDataList, setImageDataList] = useState([]);

  const hideUpdateAlert = () => {
    setShowUpdateAlert(false);
  };

  const onInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImageUpload(selectedImage);

    // Display image preview
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setImagePreview(null);
    }
  };

  useEffect(() => {
    // Fetch categories from the API when the component mounts
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://wherewasitshot.com/api/meta/moviecategory/get"
        ); // Update the API endpoint
        const categoriesData = response.data;
        setCategories(categoriesData);
      } catch (error) {
        // console.log("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const loadMovieDetails = async () => {
      try {
        if (moviesId) {
          const movieResponse = await axios.get(
            `https://wherewasitshot.com/api/movie/retrieve/${moviesId}`
          );

          const movieData = movieResponse.data;

          // Set the movie details fetched from the backend
          setMovie({
            movieName: movieData.movieName,
            description: movieData.description,
            releasedYear: movieData.releasedYear,
            starring: movieData.starring,
            music: movieData.music,
            direction: movieData.direction,
            wikiLink: movieData.wikiLink,
            language: movieData.language,
            image: movieData.image,
          });

          // Set the current image path if available
          setUploadedImagePath(movieData.image);

          // Set the image URL to the imagePreview state
          setImagePreview(movieData.image);
        }
      } catch (error) {
        // console.log("Error:", error);
      }
    };

    loadMovieDetails();
  }, [moviesId]);

  const uploadImage = (token) => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);

    uploadBytes(imageRef, imageUpload)
      .then((snapshot) => {
        alert("Image uploaded");
        getDownloadURL(snapshot.ref)
          .then((url) => {
            const postData = {
              ...movie,
              image: url,
            };

            axios
              .post("https://wherewasitshot.com/api/movie/add", postData, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                // Log the server response
                // console.log("Server response:", response.data);

                // Check if the response contains the movieId
                if (response.data.movieId) {
                  const newMovieId = response.data.movieId;
                  // Log the newly generated movie ID
                  // console.log("Newly generated movie ID:", newMovieId);

                  // Use the newMovieId in the navigation
                  navigate(`/movies/add?tab=map-location&movieId=${newMovieId}`);
                } else {
                  // Log an error if movie ID is not found in the response
                  console.error("Movie ID not found in the response");
                }

                // Update the imageDataList state
                setImageDataList((prevList) => [...prevList, postData]);
                // Reset the movie state
                setMovie({
                  movieName: "",
                  releasedYear: "",
                  starring: "",
                  music: "",
                  direction: "",
                  description: "",
                  image: "",
                  wikiLink: "",
                  language: "",
                });

                // Reset the selected image and image preview states
                setSelectedImage("");
                setImagePreview("");
              })
              .catch((error) => {
                // Handle errors when posting movie data
                console.error("Error uploading image:", error);
                if (error.response && error.response.status === 403) {
                  // Token expired, navigate to /admin
                  // console.log("Token expired. Redirecting to /admin --->Movie");
                  // alert("Unauthorized access (Token expired)");
                  navigate('/admin');
                }
              });
          })
          .catch((error) => {
            // Handle errors when fetching download URL
            console.error("Error fetching download URL:", error);
          });
      })
      .catch((error) => {
        // Handle errors when uploading image
        console.error("Error uploading image:", error);
      });
  };

  const updateMovieData = (moviesId, token) => {
    if (imageUpload) {
      // If a new image is selected, upload it first
      const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
      uploadBytes(imageRef, imageUpload)
        .then((snapshot) => {
          alert("Image uploaded");
          getDownloadURL(snapshot.ref)
            .then((url) => {
              // Update the movie data with the new image URL
              const updatedData = {
                ...movie,
                image: url,
              };
              // Make a PUT request to update the movie data
              sendUpdateRequest(updatedData, moviesId, token);
            })
            .catch((error) => {
              //console.error("Error fetching download URL:", error);
            });
        })
        .catch((error) => {
          //console.error("Error uploading image:", error);
        });
    } else {
      // If no new image is selected, update the movie data without changing the image
      const updatedData = { ...movie };
      // Remove the image property if it's null or empty
      if (!updatedData.image) {
        delete updatedData.image;
      }
      // Make a PUT request to update the movie data
      sendUpdateRequest(updatedData, moviesId, token);
    }
  };

  const sendUpdateRequest = (updatedData, moviesId, token) => {
    axios
      .put(`https://wherewasitshot.com/api/movie/update/${moviesId}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Log a success message
        // console.log(`Movie with ID ${moviesId} updated successfully.`);
        // Set a state to show a success message or perform other actions
        setShowUpdateAlert(true);
      })
      .catch((error) => {
        // Handle errors when updating movie data
        // console.error(`Error updating movie with ID ${moviesId}:`, error);
        if (error.response && error.response.status === 403) {
          // Token expired, navigate to /admin
          // console.log("Token expired. Redirecting to /admin");
          alert("Unauthorized access (Token expired)");
          navigate('/admin');
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Perform form field validation
    let isValid = true;

    // Validate each field and set error messages accordingly
    if (!movie.movieName) {
      setMovieNameError("Name Field Is Required");
      isValid = false;
    } else {
      setMovieNameError("");
    }
    if (!movie.description) {
      setDescriptionError("Description Field Is Required");
      isValid = false;
    } else {
      setDescriptionError("");
    }
    if (!movie.releasedYear) {
      setReleasedYearError("Released Year Field Is Required");
      isValid = false;
    } else {
      setReleasedYearError("");
    }
    if (!movie.starring) {
      setStarringError("Starring Field Is Required");
      isValid = false;
    } else {
      setStarringError("");
    }
    if (!movie.music) {
      setMusicError("Music Field Is Required");
      isValid = false;
    } else {
      setMusicError("");
    }
    if (!movie.direction) {
      setDirectionError("Direction Field Is Required");
      isValid = false;
    } else {
      setDirectionError("");
    }
    if (!movie.wikiLink) {
      setWikiLinkError("Wiki Link Is Required");
      isValid = false;
    } else {
      setWikiLinkError("");
    }
    if (!movie.language) {
      setLanguageError("Language Field Is Required");
      isValid = false;
    } else {
      setLanguageError("");
    }

    // Include image dimensions validation
    if (imageDimensionsError) {
      isValid = false;
    }

    // Validate image field
    if (!imageUpload && !moviesId) {
      setImageError("Image Field Is Required");
      isValid = false;
    } else {
      setImageError(""); // Clear image validation error if an image is selected
    }

    // If any validation fails, do not proceed with the submission
    if (!isValid) {
      return;
    }

    // Fetch the token from wherever you have stored it (session storage, local storage, etc.)
    const token = sessionStorage.getItem("token");

    try {
      if (moviesId) {
        // If movieIdToUpdate has a value, it means we want to update a movie
        updateMovieData(moviesId, token);
      } else {
        // Otherwise, we want to upload a new movie
        uploadImage(token); // Call the uploadImage function when the form is submitted

        // Add logic to handle response from server
        const response = await axios.post("https://wherewasitshot.com/api/movie/add", movie, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Log the response status
        // console.log("Movie add response status:", response.status);

        if (response.status === 200 && response.data.movieId) {
          // Movie added successfully
          const newMovieId = response.data.movieId;
          alert("Movie added successfully");
          navigate(`/movies/add?tab=map-location&movieId=${newMovieId}`);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      // Check if the error message indicates that the movie already exists
      if (error.response && error.response.status === 400 && error.response.data === "Movie with the same name and release year already exists") {
        alert("Movie with the same name and release year already exists");
      } else if (error.response && error.response.status === 403) {
        // Handle the case when the token expires (403 Forbidden)
        alert("Unauthorized access (Token expired)");
        navigate('/admin'); // Redirect to the /admin route
      } else {
        alert("Error adding movie");
      }
    }
  };

  const generateYearsArray = (startYear, currentYear) => {
    const presentYear = new Date().getFullYear();
    const endYear = currentYear ? Math.min(currentYear, presentYear) : presentYear;
    return Array.from(
      { length: endYear - startYear + 1 },
      (_, index) => endYear - index
    );
  };
  
  const currentYear = new Date().getFullYear();
  const years = generateYearsArray(1960, currentYear);
  console.log(years);
  

  return (
    <div className="movieformm">
      <div id="addform">
        <div className="row" id="movieform">
          <div className="border rounded p-4  shadow mx-4 ">
            <h5 className="text-center mb-4 ">
              {moviesId ? "MovieMagic Hub " : "MovieMagic Hub"}
              {moviesId && (
                <EditCalendarIcon
                  style={{
                    marginBottom: "0.2em",
                    verticalAlign: "middle",
                    fontSize: "20px",
                    color: "blue",
                  }}
                />
              )}
            </h5>

            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="moviename" className="form-label">
                      Title*
                    </label>
                    <input
                      type="text"
                      id="movieName"
                      name="movieName"
                      className="form-control"
                      value={movie.movieName}
                      onChange={(e) =>
                        setMovie({ ...movie, movieName: e.target.value })
                      }
                    />
                    <div className="text-danger">
                      <small>
                        {movieNameError ? `*${movieNameError}` : ""}
                      </small>
                    </div>
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="releasedYear" className="form-label">
                      Released Year*
                    </label>

                    <div style={{ position: "relative" }}>
                      <select
                        className="form-control"
                        name="releasedYear"
                        value={movie.releasedYear}
                        onChange={(e) =>
                          setMovie({ ...movie, releasedYear: e.target.value })
                        }
                      >
                        <option>select year</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>

                      <ArrowDropDownIcon
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "12px",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>

                    <div className="text-danger">
                      <small>
                        {releasedYearError ? `*${releasedYearError}` : ""}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="Starring" className="form-label">
                      Starring*
                    </label>
                    <input
                      type="text"
                      id="starring"
                      name="starring"
                      className="form-control"
                      value={movie.starring}
                      onChange={(e) =>
                        setMovie({ ...movie, starring: e.target.value })
                      }
                    />
                    <div className="text-danger">
                      <small>{starringError ? `*${starringError}` : ""}</small>
                    </div>
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="Music" className="form-label">
                      Music*
                    </label>
                    <input
                      type="text"
                      id="music"
                      name="music"
                      className="form-control"
                      value={movie.music}
                      onChange={(e) =>
                        setMovie({ ...movie, music: e.target.value })
                      }
                    />
                    <div className="text-danger">
                      <small>{musicError ? `*${musicError}` : ""}</small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="Direction" className="form-label">
                      Direction*
                    </label>
                    <input
                      type="text"
                      id="direction"
                      name="direction"
                      className="form-control"
                      value={movie.direction}
                      onChange={(e) =>
                        setMovie({ ...movie, direction: e.target.value })
                      }
                    />
                    <div className="text-danger">
                      <small>
                        {directionError ? `*${directionError}` : ""}
                      </small>
                    </div>
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="wikiLink" className="form-label">
                      Wiki Link*
                    </label>
                    <input
                      type="text"
                      id="wikiLink"
                      name="wikiLink"
                      className="form-control"
                      value={movie.wikiLink}
                      onChange={(e) =>
                        setMovie({ ...movie, wikiLink: e.target.value })
                      }
                    />
                    <div className="text-danger">
                      <small>{wikiLinkError ? `*${wikiLinkError}` : ""}</small>
                    </div>
                  </div>
                </div>

                <div className="mb-3 ">
                  <label htmlFor="Description" className="form-label">
                    Description*
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    className="form-control"
                    value={movie.description}
                    onChange={(e) =>
                      setMovie({ ...movie, description: e.target.value })
                    }
                  />
                  <div className="text-danger">
                    <small>
                      {descriptionError ? `*${descriptionError}` : ""}
                    </small>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="Image" className="form-label">
                      Image*
                    </label>
                    <input
                      type="file"
                      id="image"
                      accept="image/*"
                      className="form-control"
                      onChange={handleImageChange}
                    />
                    {imagePreview && (
                      <div className="mb-3">
                        <img
                          src={imagePreview}
                          alt="Image Preview"
                          className="img-thumbnail"
                          width="200"
                        />
                      </div>
                    )}
                    {imageError && (
                      <div className="text-danger">
                        <small>{imageError}</small>
                      </div>
                    )}
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="Language" className="form-label">
                      Language*
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="language"
                      value={movie.language}
                      onChange={(e) =>
                        setMovie({ ...movie, language: e.target.value })
                      }
                      onKeyDown={onInputKeyPress}
                    >
                      <option className="selectlang" value="">
                        Select a language
                      </option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                    <div className="text-danger">
                      <small>{languageError ? `*${languageError}` : ""}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center">
                    {moviesId ? (
                      <button
                        type="submit"
                        className="btn btn-outline-primary m-2"
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-outline-primary m-2"
                      >
                        Submit
                      </button>
                    )}

                    <Link className="btn btn-outline-danger mx-2" to="/movies">
                      Cancel
                    </Link>
                    {showUpdateAlert && (
                      <div className="alert alert-success mt-3">
                        Successfully updated!
                        <button
                          type="button"
                          className="btn-close"
                          onClick={hideUpdateAlert}
                          aria-label="Close"
                        ></button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieForm;
